import React from 'react';
import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  occasionCard: {
    height: 175,
    width: 230,
    textDecoration: 'none',
    color: 'black',
  },
  link: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const OccasionTile = ({
  occasion,
  occasionId,
  occasionsWithCard,
  selectFlow,
  selectCard,
  selectOccasion,
  selectFrequency,
  setCustomCardId,
}) => {
  const classes = useStyles();
  const hasCardForOccasion = occasionsWithCard.has(occasion);

  const enterFlow = (flow) => {
    selectOccasion();
    selectFrequency();
    selectFlow(flow);
  };

  const setCard = () => {
    const card = occasionsWithCard.get(occasion);
    setCustomCardId(card.customCardId);
    selectCard(card.card);
  };

  return (
    <Grid id={'occasion-tile-' + occasion} item>
      <Card raised>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          className={classes.occasionCard}
          spacing={1}
        >
          <Grid item>
            <Typography id={'occasion-title-' + occasionId} variant='h4' align='center'>
              {occasion}
            </Typography>
          </Grid>
          <Grid container item justify='center'>
            <Link
              className={classes.link}
              onClick={() => {
                if (hasCardForOccasion) {
                  setCard();
                  enterFlow('modify');
                } else {
                  enterFlow('create');
                }
              }}
              to={'/select'}
            >
              {hasCardForOccasion ? 'Select Card' : 'Create Card'}
            </Link>
          </Grid>
          <Grid container item justify='center'>
            <Link
              className={classes.link}
              onClick={() => {
                setCard();
                enterFlow('modify');
              }}
              to={hasCardForOccasion ? '/customize' : '/select'}
              style={{ display: hasCardForOccasion ? 'initial' : 'none' }}
            >
              Modify Card
            </Link>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default OccasionTile;
