import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  iconButton: {
    color: 'black',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    display: 'flex',
    overflowY: 'visible',
    height: '275px',
    justifyContent: 'center',
    alignContent: 'center',
  },
  deleteDialogContent: {
    display: 'flex',
    overflowY: 'visible',
    height: '100px',
    justifyContent: 'center',
    alignContent: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  dialogText: {
    lineHeight: '1.3',
  },
}));

const AddRecipientsIcon = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setDeletePopupOpen(false);
  };

  const handleDialog = () => {
    if (props.hasRecipients) {
      setOpen(true);
    } else {
      props.handleSubmit(false);
    }
  };

  const handleDeleteDialog = () => {
    setDeletePopupOpen(true);
  };

  return (
    <div>
      <input
        accept='.csv'
        className={classes.input}
        id='icon-upload'
        type='file'
        onChange={() => handleDialog()}
      />
      <label
        htmlFor='icon-upload'
        style={{ position: 'absolute', top: '-40px', right: '0px' }}
      >
        <Tooltip
          id='add-recipients-tooltip'
          title={
            <Typography id='add-recipients-tooltip-text'>
              {props.hasRecipients
                ? 'Add to or replace recipients here!'
                : 'Add recipients here!'}
            </Typography>
          }
          arrow
          placement='top'
        >
          <IconButton
            size='small'
            classes={{ root: classes.iconButton }}
            disableRipple
            component='span'
          >
            <GroupAddIcon
              id='add-recipients-icon'
              style={{ fontSize: '2.5rem' }}
            />
          </IconButton>
        </Tooltip>
      </label>
      {props.hasRecipients ?
      <label
        style={{ position: 'absolute', top: '-40px', right: '45px' }}
      >
        <Tooltip
          id='delete-recipients-tooltip'
          title={
            <Typography id='delete-recipients-tooltip-text'>
              Delete all recipients here!
            </Typography>
          }
          arrow
          placement='top'
        >
          <IconButton
            size='small'
            classes={{ root: classes.iconButton }}
            disableRipple
            component='span'
            onClick={ handleDeleteDialog }
          >
            <RemoveIcon
              id='delete-recipients-icon'
              style={{ fontSize: '2.5rem' }}
            />
          </IconButton>
        </Tooltip>
      </label> : <></>}
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>Add or Replace?</DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <Grid container direction='column' justify='space-evenly'>
            <Grid item>
              <Typography
                align='center'
                variant='h6'
                classes={{ h6: classes.dialogText }}
              >
                Would you like to Add the Recipients <br />
                in the new file to your existing <br />
                list of Recipients?{' '}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align='center'
                variant='h6'
                classes={{ h6: classes.dialogText }}
              >
                Or, would you like to Delete your <br />
                existing Recipients and Replace them <br />
                with those in the new file?
              </Typography>
            </Grid>
            <Grid container item justify='center' spacing={3}>
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleClose();
                    props.handleSubmit(false);
                  }}
                  color='primary'
                >
                  Add to Existing
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleClose();
                    props.handleSubmit(true);
                  }}
                  color='primary'
                >
                  Delete and Replace
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button variant='contained' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deletePopupOpen}
        onClose={handleDeleteClose}
        disableBackdropClick
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'>Delete Recipients?</DialogTitle>
        <DialogContent dividers classes={{ root: classes.deleteDialogContent }}>
          <Grid container direction='column' justify='space-evenly'>
            <Grid item>
              <Typography
                align='center'
              >
                This will delete all existing Recipients for the card
              </Typography>
            </Grid>
            <Grid container item justify='center' spacing={3}>
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleDeleteClose();
                    props.handleDelete();
                  }}
                  color='primary'
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleDeleteClose();
                  }}
                  color='primary'
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddRecipientsIcon;
