export const getNumericMonthDayYearFromLocalDate = (dateString) => {
  const newDateString = dateString.replace(/-/g, '/');
  return new Intl.DateTimeFormat('en-US').format(new Date(newDateString));
};

export const getLongMonthNameAndYear = (dateString) => {
  var options = { month: 'long', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(dateString);
};

export const getDateFromLocalDate = (dateString) => {
  const newDateString = dateString.replace(/-/g, '/');
  return new Date(newDateString);
};

export const getNumericMonthDayYearFromTimestamp = (dateString) => {
  return new Intl.DateTimeFormat('en-US').format(new Date(dateString));
};

export const getFullFormattedDateTime = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'long' }).format(date);
  return formattedDate;
};