import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const CardSelectorDialog = ({
  open,
  setOpen,
  activeCard,
  occasion,
  existingCards,
  handleNext,
  selectCard,
  setCustomCardId,
}) => {
  const history = useHistory();
  const existingCard = existingCards[occasion];

  const setCard = () => {
    setCustomCardId(existingCard.customCardId);
    selectCard(existingCard.card);
  };

  const confirmCardReplacement = async () => {
    await deleteCustomCardDesigns();
    handleNext(activeCard);
  };

  const deleteCustomCardDesigns = () => {
    const path = '/card/deleteCustomCardDesigns';
    var url = new URL(path, process.env.REACT_APP_LANDING_URL);
    const params = { customCardId: existingCard.customCardId };
    url.search = new URLSearchParams(params).toString();
    return fetch(url);
  };

  return (
    <Dialog open={open} disableBackdropClick maxWidth='sm'>
      <DialogTitle id='dialog-title' disableTypography>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h5'>You have Selected a New Card</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' justify='space-around' alignContent='center'>
          <Typography id='new-card-question' style={{ marginBottom: '15px' }}>
            Your new card will replace your existing one. Any text/media changes will have to be
            promptly redone as the new card will be immediately scheduled for printing.
          </Typography>
          <Typography>Would you like to replace and customize your existing card?</Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-around', padding: '20px 24px' }}>
        <Button
          id='deny-button'
          variant='contained'
          color='primary'
          onClick={() => {
            setCard();
            history.push('/customize');
          }}
        >
          No, keep existing card.
        </Button>
        <Button
          id='confirm-button'
          variant='contained'
          color='primary'
          onClick={confirmCardReplacement}
        >
          {'Yes, replace & customize'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardSelectorDialog;
