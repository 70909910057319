import { makePostRequest } from 'utils/CRUDUtils';
import {
  getEnvelopeStocks,
  getPaperStocks,
  getSavedCardDesigns,
} from 'components/customizeCard/CustomCardGetters';

export const createCustomCard = async (card) => {
  const paperStocks = await getPaperStocks(card);
  const envelopeStocks = await getEnvelopeStocks(card);
  card.paperStockId = paperStocks[0].paperStockId; //setting default paper stock
  card.envelopeStockId = envelopeStocks[0].envelopeStockId; //seting default envelope stock

  const customCard = {
    card: card,
    accountId: card.accountId,
    paperStockId: card.paperStockId,
    envelopeStockId: card.envelopeStockId,
    pdfUrl: '',
  };

  const path = '/card/createCustomCard';
  return makePostRequest(path, customCard);
};

export const updateCustomCard = (card) => {
  const customCard = {
    customCardId: card.customCardId,
    card: card,
    accountId: card.accountId,
    paperStockId: card.paperStockId,
    envelopeStockId: card.envelopeStockId,
    pdfUrl: card.pdfUrl,
    frontPaneImage: card.frontPaneImage,
    insideLeftPaneImage: card.insideLeftPaneImage,
    insideRightPaneImage: card.insideRightPaneImage,
    backPaneImage: card.backPaneImage
  };

  const path = '/card/updateCustomCard';
  return makePostRequest(path, customCard);
};

export const setLocalCustomCardData = (localCard, savedCustomCard) => {
  localCard.customCardId = savedCustomCard.customCardId;
  localCard.paperStockId = savedCustomCard.paperStockId;
  localCard.envelopeStockId = savedCustomCard.envelopeStockId;
};

export const setCustomCardMediaInfo = async (card, fileName) => {
  const customCardMedia = {
    customCardMediaId: card.customCardMediaId,
    customCardId: card.customCardId,
    mediaName: fileName,
    mediaUrl: card.mediaUrl,
  };

  const path = '/card/updateAndInsertCustomCardMedia';
  const response = await makePostRequest(path, customCardMedia);
  const result = await response.json();
  card.customCardMediaId = result;
};

export const updateCardDesigns = async (card, designMaterials) => {
  const index = designMaterials.activePane;
  card.customCardDesigns[index].data = designMaterials.data;

  const path = '/card/updateCustomCardDesigns';
  const response = await makePostRequest(path, [card.customCardDesigns[index]]);
  if (response.ok) console.log('Card saved!');
};

export const createCustomCardDesign = (card, content) => {
  const cardDesign = {
    customCardId: card.customCardId,
    ...content,
  };
  return cardDesign;
};

export const sendCardDesigns = async (card) => {
  const path = '/card/createCustomCardDesigns';
  await makePostRequest(path, card.customCardDesigns);

  const defaultStocks = {
    paper: card.paperStockId,
    envelope: card.envelopeStockId,
  };
  return getSavedCardDesigns(card, defaultStocks);
};
