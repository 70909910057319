import React, { useState } from 'react';
import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import VisibleRecipientSchedule from 'components/scheduleCard/containers/VisibleRecipientSchedule';
import { makeJsonGetRequest, makePostRequest, makeGetRequest } from 'utils/CRUDUtils';
import MissedBatchRecipientsDialog from 'components/scheduleCard/MissedBatchRecipientsDialog';
import { useHistory } from 'react-router';
import keycloak from 'config/keycloak';
import CardCostDialog from 'components/payment/CardCostDialog';
import { getDateFromLocalDate } from 'utils/DateUtils';

const useStyles = makeStyles((theme) => ({
  approveButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const Scheduler = ({ occasion, func }) => {
  const classes = useStyles();
  const history = useHistory();
  const [costViewDialogOpen, setCostViewDialogOpen] = useState(false);
  const [missedBatchDialogOpen, setMissedBatchDialogOpen] = useState(false);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);
  const [recipientSchedules, setRecipientSchedules] = useState([]);
  const [currentMonthRecipientsResponse, setCurrentMonthRecipientsResponse] = useState(null);
  const [hasRecipientsToSchedule, setHasRecipientsToSchedule] = useState(false);

  const handleBack = () => {
    func.handleBack();
  };

  const addToSchedule = () => {
    if (
      !currentMonthRecipientsResponse ||
      currentMonthRecipientsResponse.recipientsInPastBatch.length === 0
    ) {
      scheduleRecipients();
    } else {
      setMissedBatchDialogOpen(true);
    }
  };

  const scheduleRecipients = async (hasCardsForNextPrintDate) => {
    const params = { keycloakId: keycloak.subject };
    const profile = await makeJsonGetRequest('/cardpointe/getProfile', params);
    const paymentType = await makeGetRequest('/payment/getPaymentType', {});
    let canUseCreditCard = false;
    if (paymentType === 'CreditCard') {
      canUseCreditCard = true;
    }

    const path = '/scheduler/scheduleRecipients';
    if (hasCardsForNextPrintDate) {
      const recipientsInPastBatch = currentMonthRecipientsResponse.recipientsInPastBatch;
      for (const recipient of recipientsInPastBatch) {
        const batchEndDate = getDateFromLocalDate(currentMonthRecipientsResponse.pastBatch.batchEndDate);
        batchEndDate.setDate(batchEndDate.getDate() + 1);
        recipient.batchDate = batchEndDate;
      }

      const body = {
        recipientSchedules: recipientSchedules,
        recipientsInPastBatch: recipientsInPastBatch,
      };
      await makePostRequest(path, body);
    } else {
      const body = { recipientSchedules: recipientSchedules, recipientsInPastBatch: [] };
      await makePostRequest(path, body);
    }
    if (!profile && canUseCreditCard) {
      window.location = 'https://nfosmart.securepayments.cardpointe.com/pay?cf_hidden_KeycloakId=' + keycloak.subject + '&total=0.01';
    } else {
      func.handleNext();
    }
  };

  return (
    <Grid
      container
      direction='column'
      justify='space-around'
      alignItems='center'
      xs={10}
      spacing={2}
    >
      <Grid item>
        <Typography id='occasion-name-display' variant='h4' align='center' gutterBottom>
          Review Schedule
        </Typography>
      </Grid>
      <Grid
        container
        item
        className='full-width'
        justify='space-between'
        direction='column'
        alignItems='center'
        wrap='nowrap'
        style={{
          position: 'relative',
        }}
      >
        <Grid container direction='column' alignItems='center'>
          {!hasRecipientsToSchedule && (
            <Typography variant='h6' gutterBottom>
              <b>All of your current recipients have been scheduled!</b>
            </Typography>
          )}
          <Typography style={{ color: 'grey', marginBottom: '15px' }}>
            <i>Schedule last updated on {lastUpdateDate}</i>
          </Typography>
        </Grid>
        <VisibleRecipientSchedule
          setLastUpdateDate={setLastUpdateDate}
          setRecipientSchedules={setRecipientSchedules}
          setHasRecipientsToSchedule={setHasRecipientsToSchedule}
          setCurrentMonthRecipientsResponse={setCurrentMonthRecipientsResponse}
        />
      </Grid>
      <Grid container item justify='space-between'>
        <Button id='back-button' variant='contained' color='primary' onClick={() => handleBack()}>
          <Grid container justify='space-between'>
            <ArrowBackRoundedIcon />
            <Typography id='back-button-text'>Back</Typography>
          </Grid>
        </Button>
        <Button
          id='schedule-button'
          variant='contained'
          color='primary'
          onClick={() => (hasRecipientsToSchedule ? setCostViewDialogOpen(true) : history.push('/home'))}
          classes={{ containedPrimary: classes.approveButton }}
          component={Link}
        >
          <Grid container justify='space-between'>
            <Typography id='schedule-button-text'>
              {hasRecipientsToSchedule ? 'Approve & Pay' : 'Dashboard'}
            </Typography>
          </Grid>
        </Button>
        {currentMonthRecipientsResponse && (
          <MissedBatchRecipientsDialog
            open={missedBatchDialogOpen}
            scheduleRecipients={scheduleRecipients}
            response={currentMonthRecipientsResponse}
            occasion={occasion}
            setOpen={setMissedBatchDialogOpen}
          />
        )}
        <CardCostDialog
          open={costViewDialogOpen}
          setOpen={setCostViewDialogOpen}
          addToSchedule={addToSchedule}
        />
      </Grid>
    </Grid>
  );
};

export default Scheduler;
