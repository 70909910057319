import { connect } from 'react-redux';
import Customizer from 'components/customizeCard/Customizer';

const mapStateToProps = (state, ownProps) => {
  return {
    orientation: state.currentCard.orientation,
    func: ownProps.func,
    cardId: state.currentCard.card,
  };
};

export default connect(mapStateToProps)(Customizer);
