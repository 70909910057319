import { connect } from 'react-redux';
import CardStepper, { StepContent } from 'components/base/CardStepper';
import { completeTask, undoTask } from 'redux/actions/OccasionActions';

const mapStateToProps = (state) => {
  const occasion = {
    occasion: state.currentCard.occasion,
    freq: state.currentCard.frequency,
  };
  return {
    occasion: occasion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCompleteTask: (occasion, id) => dispatch(completeTask(occasion, id)),
  dispatchUndoTask: (occasion, id) => dispatch(undoTask(occasion, id)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    completeTask: (id) => dispatchProps.dispatchCompleteTask(stateProps.occasion, id),
    undoTask: (id) => dispatchProps.dispatchUndoTask(stateProps.occasion, id),
    activeStep: ownProps.activeStep,
    checkpoint: ownProps.checkpoint,
    lastSubTask: ownProps.lastSubTask,
    index: ownProps.index,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CardStepper);

const mapStateToStepProps = (state, ownProps) => {
  return {
    occasionId: state.occasionProps[state.currentCard.occasion],
    activeStep: ownProps.activeStep,
    index: ownProps.index,
    func: ownProps.func,
  };
};

export const VisibleStepContent = connect(mapStateToStepProps)(StepContent);
