import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const DragAndDrop = (props) => {
  const [dragging, setDragging] = useState(false);
  const [elementLayer, setElementLayer] = useState(0);

  useEffect(() => {
    if (elementLayer === 0) {
      setDragging(false);
    }
  }, [elementLayer]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIntoLayer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setElementLayer(elementLayer + 1);
    setDragging(true);
  };

  const handleDragOutOfLayer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setElementLayer(elementLayer - 1);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.handleDrop(e.dataTransfer.files);
    setElementLayer(0);
  };

  return (
    <Grid
      id='dropzone'
      style={{ display: 'inline-block', position: 'relative' }}
      onDragEnter={(e) => handleDragIntoLayer(e)}
      onDragLeave={(e) => handleDragOutOfLayer(e)}
      onDragOver={(e) => handleDrag(e)}
      onDrop={(e) => handleDrop(e)}
      xs={11}
    >
      {dragging ? (
        <div
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36,
            }}
          >
            <div id='dropzone-msg'>drop here</div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {props.children}
    </Grid>
  );
};

export default DragAndDrop;
