import Quill from 'quill';

export const initiateQuill = (editorId) => {
  var Font = Quill.import('formats/font');
  Font.whitelist = ['arial'];
  Quill.register(Font, true);

  var Size = Quill.import('formats/size');
  Size.whitelist = ['18px'];
  Quill.register(Size, true);

  var container = document.getElementById('editor-' + editorId);
  var quill = new Quill(container, {
    theme: 'bubble',
    modules: {
      toolbar: '#toolbar-container',
    },
    placeholder: 'Add business name',
  });

  quill.format('align', 'center');
  return quill;
};
