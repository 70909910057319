import React, { useState } from 'react';
import keycloak from 'config/keycloak';
import {
  Avatar,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { logout } from 'redux/actions/UserActions';
import DeleteAccountDialog from 'components/base/DeleteAccountDialog';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const ProfileIcon = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const accessAccountDetails = () => {
    keycloak.accountManagement();
  };

  const openAdminPage = () => {
    history.push('/administration')
  };

  const openDeleteAccountDialog = () => {
    setDialogOpen(true);
  };

  const logOut = () => {
    props.userLogout();
    keycloak.logout({ redirectUri: process.env.REACT_APP_LANDING_URL });
  };

  return (
    <div>
      <IconButton
        aria-label='account of current user'
        aria-controls='user-menu'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <Avatar
          id='user-avatar'
          alt={keycloak.idTokenParsed.given_name}
          src='/broken-image.jpg' //TODO: Take away the broken-image src
          className={classes.small}
        />
      </IconButton>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          id='user-menu-account'
          onClick={(handleClose, accessAccountDetails)}
        >
          My Account
        </MenuItem>
        {
          keycloak.hasRealmRole('nfosmartAdmin') ?
          <MenuItem
            id='user-menu-admin'
            onClick={(handleClose, openAdminPage)}
          >
            Administration
          </MenuItem> : <></>
        }
        <MenuItem id='user-menu-signout' onClick={logOut}>
          Sign Out
        </MenuItem>
        <MenuItem
          id='user-menu-deleteAccount'
          onClick={(handleClose, openDeleteAccountDialog)}
        >
          Delete Account
        </MenuItem>
      </Menu>
      <DeleteAccountDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        logOut={logOut}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userLogout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(ProfileIcon);
