export const isExceedingFileSizeLimit = (file, input) => {
  if (file.size > process.env.REACT_APP_MAX_FILE_BYTES) {
    alert(
      'Max file size of ' +
        process.env.REACT_APP_MAX_FILE_MB +
        'MB exceeded. Please upload a smaller file'
    );
    input.value = null;
    return true;
  }
  return false;
};

export const isExceedingVideoDurationLimit = (file, input) => {
  if (file.type.match('video.*')) {
    var vid = document.createElement('video');
    var fileURL = URL.createObjectURL(file);
    vid.src = fileURL;
    vid.ondurationchange = function () {
      return this.duration > process.env.REACT_APP_MAX_VIDEO_DURATION_SECONDS;
    };

    if (vid.ondurationchange()) {
      alert(
        'Max video duration of ' +
          process.env.REACT_APP_MAX_VIDEO_DURATION_SECONDS +
          ' seconds exceeded. Please upload a shorter video'
      );
      input.value = null;
      return true;
    }
  }
};
