import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import React from 'react';
import RecipientsForCurrentMonthGrid from 'components/scheduleCard/RecipientsForCurrentMonthGrid';
import { getLongMonthNameAndYear, getNumericMonthDayYearFromLocalDate } from 'utils/DateUtils';
import CloseIcon from '@material-ui/icons/Close';

const MissedBatchRecipientsDialog = ({ response, scheduleRecipients, open, occasion, setOpen }) => {
  const startDate = getNumericMonthDayYearFromLocalDate(response.pastBatch.batchStartDate);
  const endDate = getNumericMonthDayYearFromLocalDate(response.pastBatch.batchEndDate);
  const nextPrintDate = getNumericMonthDayYearFromLocalDate(response.nextPrintDate);
  const recipientsInCurrentMonth = response.recipientsInPastBatch;
  const currentDate = new Date();
  const currentMonthYear = getLongMonthNameAndYear(currentDate);

  return (
    <Dialog open={open} disableBackdropClick maxWidth='sm'>
      <DialogTitle id='alert-dialog-title' disableTypography>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6'>{currentMonthYear} - Missed Recipients</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justify='center'>
          <Grid container justify='center' spacing={3} xs={10}>
            <Grid item>
              <Typography id='alert-dialog-description' align='center'>
                The following <b>{recipientsInCurrentMonth.length}</b> recipients in the new file
                have a {occasion} in{' '}
                <b>
                  {startDate} - {endDate}
                </b>
                .
              </Typography>
            </Grid>
            <Grid item>
              <Typography id='alert-dialog-description' align='center'>
                We've already processed cards for that period.
              </Typography>
            </Grid>
            <Grid item>
              <Typography id='alert-dialog-description' align='center'>
                Would you like to mail cards to those {recipientsInCurrentMonth.length} recipients
                on <b>{nextPrintDate}</b>?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <RecipientsForCurrentMonthGrid recipients={recipientsInCurrentMonth} />
        <Grid container justify='space-evenly' style={{ marginTop: '20px' }}>
          <Button
            id='no-button'
            variant='contained'
            color='primary'
            onClick={() => scheduleRecipients(false)}
          >
            No
          </Button>
          <Button
            id='yes-button'
            variant='contained'
            color='primary'
            onClick={() => scheduleRecipients(true)}
          >
            Yes
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MissedBatchRecipientsDialog;
