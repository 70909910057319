import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { MainHeader } from 'components/base/Header';
import { MainFooter } from 'components/base/Footer';
import { makeJsonGetRequest } from 'utils/CRUDUtils';
import keycloak from 'config/keycloak';
import { getLongMonthNameAndYear, getDateFromLocalDate } from 'utils/DateUtils';
import PaymentGrid from 'components/payment/PaymentGrid';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  tableContainer: {
    maxHeight: 300
  },
  tableCell: {
    fontSize: '1.1rem'
  }
}));

const HistoricalSchedule = () => {
  const classes = useStyles();
  const [transactionData, setTransactionData] = useState(null);
  const [occasionData, setOccasionData] = useState(null);

  const [scheduleData, setScheduleData] = useState(null);
  const [scheduleOccasionData, setScheduleOccasionData] = useState(null);

  const columns = [
    { id: 'month', label: 'Month Purchased' },
    { id: 'total', label: 'Cards Purchased' },
    { id: 'paid', label: 'Paid' },
  ];
  const scheduledColumns = [
    { id: 'month', label: 'Month Billed' },
    { id: 'total', label: 'Scheduled Cards' },
    { id: 'paid', label: 'Scheduled Payments' },
  ];
  const [rows, setRows] = useState([]);
  const [scheduleRows, setScheduleRows] = useState([]);

  useEffect(() => {
    const createRowMap = async (card, cardSchedules) => {
      const rowMap = new Map();
      const transactionMap = new Map();
      const occasionMap = new Map();
      for (const schedule of cardSchedules) {
        if (schedule.transaction) {
          const formattedDate = new Date(schedule.transaction.createdOn);
          const monthAndYear = getLongMonthNameAndYear(formattedDate);
          const rowKey = monthAndYear;
          const rowValue = rowMap.get(rowKey);
          if (rowValue) {
            rowMap.set(rowKey, [rowValue[0], rowValue[1] + 1]);
          } else {
            rowMap.set(rowKey, [formattedDate, 1]);
          }

          let transactionScheduleMap = transactionMap.get(rowKey);
          const transactionScheduleMapKey = schedule.transaction.transactionId;
          if (transactionScheduleMap) {
            const transactionScheduleValue = transactionScheduleMap.get(transactionScheduleMapKey);
            if (transactionScheduleValue) {
              transactionScheduleMap.set(transactionScheduleMapKey, [schedule.transaction, transactionScheduleValue[1] + 1]);
            } else {
              transactionScheduleMap.set(transactionScheduleMapKey, [schedule.transaction, 1]);
            }
          } else {
            transactionScheduleMap = new Map();
            transactionScheduleMap.set(transactionScheduleMapKey, [schedule.transaction, 1]);
            transactionMap.set(rowKey, transactionScheduleMap);
          }
          let occasionTransactionMap = occasionMap.get(rowKey);
          let transactionId = schedule.transaction.transactionId;
          let occasionName = "";
          if (card) {
            occasionName = card.card.occasion.occasionName;
          } else {
            occasionName = schedule.recipient.customCard.card.occasion.occasionName;
          }
          
          if (occasionTransactionMap) {
            let occasionCountMap = occasionTransactionMap.get(transactionId);
            if (occasionCountMap) {
              let occasionEntry = occasionCountMap.get(occasionName);
              if (occasionEntry) {
                occasionCountMap.set(occasionName, occasionEntry + 1);
              } else {
                occasionCountMap.set(occasionName, 1);
              }
            } else {
              occasionCountMap = new Map();
              occasionCountMap.set(occasionName, 1);
              occasionTransactionMap.set(transactionId, occasionCountMap);
            }
          } else {
            let occasionCountMap = new Map();
            occasionCountMap.set(occasionName, 1);
            occasionTransactionMap = new Map();
            occasionTransactionMap.set(transactionId, occasionCountMap);
            occasionMap.set(rowKey, occasionTransactionMap);
          }
        }
      }
      return {rowMap, transactionMap, occasionMap};
    };

    const getHistoricalCustomCards = async () => {
      let path = '/scheduler/getHistoricalCustomCards';
      let params = { accountId: keycloak.subject };
      const customCards = await makeJsonGetRequest(path, params);
      const rowMap = new Map();
      const transactionMap = new Map();
      const occasionMap = new Map();

      for (const card of customCards) {
        path = '/scheduler/getHistoricalRecipientSchedulesForCard';
        params = { customCardId: card.customCardId };
        const cardSchedules = await makeJsonGetRequest(path, params);
        const mapData = await createRowMap(card, cardSchedules);

        if (mapData) {
          mapData['rowMap'].forEach((value, key) => rowMap.set(key, value));
          mapData['transactionMap'].forEach((value, key) => transactionMap.set(key, value));
          mapData['occasionMap'].forEach((value, key) => occasionMap.set(key, value));
        }
      }

      if (rowMap.size !== 0) {
        const sortedMap = new Map([...rowMap.entries()].sort((a, b) => a[1][0] - b[1][0]));
        setTransactionData(transactionMap);
        setOccasionData(occasionMap);

        for (let [key, value] of sortedMap.entries()) {
          let totalCost = 0;
          for (let [transactionKey, transactionValue] of transactionMap.get(key).entries()) {
            totalCost = totalCost + transactionValue[0].cardCost + transactionValue[0].stampCost + transactionValue[0].salesTaxCost;
          }

          setRows((prevRows) => {
            const newArr = [...prevRows];
            newArr.push({
              month: key,
              total: value[1],
              paid: '$' + totalCost.toFixed(2),
            });
            return newArr;
          });
        }
      }
    };

    const getScheduledPayments = async () => {
      let path = '/payment/getScheduledPayments';
      let params = { accountId: keycloak.subject };
      const cardSchedules = await makeJsonGetRequest(path, params);
      const mapData = await createRowMap(null, cardSchedules);
      const rowMap = mapData['rowMap'];
      const transactionMap = mapData['transactionMap'];
      const occasionMap = mapData['occasionMap'];

      if (rowMap.size !== 0) {
        const sortedMap = new Map([...rowMap.entries()].sort((a, b) => a[1][0] - b[1][0]));
        setScheduleData(transactionMap);
        setScheduleOccasionData(occasionMap);

        for (let [key, value] of sortedMap.entries()) {
          let totalCost = 0;
          for (let [transactionKey, transactionValue] of transactionMap.get(key).entries()) {
            totalCost = totalCost + transactionValue[0].cardCost + transactionValue[0].stampCost + transactionValue[0].salesTaxCost;
          }

          setScheduleRows((prevRows) => {
            const newArr = [...prevRows];
            newArr.push({
              month: key,
              total: value[1],
              paid: '$' + totalCost.toFixed(2),
            });
            return newArr;
          });
        }
      }
    };

    getHistoricalCustomCards();
    getScheduledPayments();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item container>
        <MainHeader />
      </Grid>
      <Grid container direction='row' className={classes.fullHeight}>
        <PaymentGrid
          headerText="Card Billing Schedule"
          emptyText="No cards are currently scheduled. Please schedule cards to update this."
          columns={scheduledColumns}
          rows={scheduleRows}
          transactionData={scheduleData}
          occasionData={scheduleOccasionData}
        >
        </PaymentGrid>
        <PaymentGrid
          headerText="Card History"
          emptyText="No history currently exists. All purchased cards will appear here."
          columns={columns}
          rows={rows}
          transactionData={transactionData}
          occasionData={occasionData}
        >
        </PaymentGrid>
    </Grid>
    <MainFooter />
  </Grid>
  );
};

export default HistoricalSchedule;
