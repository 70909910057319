export const makeGetRequest = async (path, params) => {
  var url = new URL(path, process.env.REACT_APP_LANDING_URL);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }
  const response = await fetch(url);
  return response.text();
};

export const makeFileGetRequest = async (path, params) => {
  var url = new URL(path, process.env.REACT_APP_LANDING_URL);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }
  const response = await fetch(url);
  return response;
};

export const makeJsonGetRequest = async (path, params) => {
  const result = await makeGetRequest(path, params);
  if (result) {
    return JSON.parse(result);
  }
};

export const makePostRequest = async (path, body, params) => {
  var url = new URL(path, process.env.REACT_APP_LANDING_URL);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return response;
};

export const makeFilePostRequest = async (path, file, params) => {
  var url = new URL(path, process.env.REACT_APP_LANDING_URL);
  if (params) {
    url.search = new URLSearchParams(params).toString();
  }

  let formData = new FormData();
  if (file) {
    formData.append('file', file);
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    return response;
  } else {
    alert('No file selected!');
  }
};
