import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import NfoSmartApp from 'NfoSmartApp';
import * as serviceWorker from 'serviceWorker';

//React Render on authentication
ReactDOM.render(
  <React.StrictMode>
    <NfoSmartApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
