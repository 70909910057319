const initialState = {
  frequency: null,
  occasion: null,
  card: null,
  orientation: null,
  scheduleId: null,
  customCardId: null,
  flow: null,
};

export const currentCard = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_OCCASION':
      return {
        ...state,
        occasion: action.occasion,
      };
    case 'SELECT_FREQUENCY':
      return {
        ...state,
        frequency: action.frequency,
      };
    case 'SELECT_FLOW':
      return {
        ...state,
        flow: action.flow,
      };
    case 'SELECT_CARD':
      return {
        ...state,
        card: action.card.cardId,
        orientation: action.card.orientation.orientation,
        scheduleId: action.card.scheduleFrequencyId,
      };
    case 'SET_CUSTOM_CARD_ID':
      return {
        ...state,
        customCardId: action.customCardId,
      };
    default:
      return state;
  }
};

export const occasionProps = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_OCCASION_PROPS':
      return {
        ...state,
        [action.occasion.occasionName]: action.occasion.occasionId,
      };
    default:
      return state;
  }
};

export default currentCard;
