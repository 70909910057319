import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Transaction from 'components/payment/Transaction';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  },
  tableContainer: {
    maxHeight: 300
  },
  tableCell: {
    fontSize: '1.1rem'
  }
}));

const PaymentGrid = ({ headerText, emptyText, columns, rows, transactionData, occasionData }) => {
  const classes = useStyles();
  const hasData = rows.length > 0;
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const openTransactionDialog = (event) => {
    const selectedRow = event.target.parentElement.parentElement;
    const monthCell = selectedRow.cells[0];
    const month = monthCell.innerHTML;
    setSelectedMonth(month);
    setDialogOpen(true);
  };

  return (
    <Grid container direction='column' lg={12} alignContent='center' alignItems='center' spacing={3}>
      <Grid container item justify='center'>
        <Typography
          id='history-greeting-txt'
          variant='h3'
          align='center'
          className={classes.greeting}
          color='primary'
        >
          <b>{headerText}</b>
        </Typography>
      </Grid>
      <Grid container xs={12} lg={9} xl={6} spacing={7}>
        <Grid container item style={{ flex: 1 }} id='history-grid' className={classes.fullWidth}>
          {hasData ? (
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                aria-label='sticky table'
                style={{
                  borderRight: '2px solid black',
                  borderLeft: '2px solid black',
                  borderBottom: '2px solid black',
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={column.id}
                        size='small'
                        className={classes.tableCell}
                        style={{
                          borderTop: '2px solid black',
                          borderBottom: '2px solid black',
                          borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                        }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        role='checkbox'
                        key={'row-' + index}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                        }}
                      >
                        {columns.map((column, i) => {
                          const value = row[column.id];
                          if (i !== columns.length - 1) {
                            return (
                              <TableCell
                                key={column.id}
                                size='small'
                                className={classes.tableCell}
                                style={{
                                  borderRight: '2px solid black'
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={column.id}
                                size='small'
                                className={classes.tableCell}
                                style={{
                                  borderRight: 'none'
                                }}
                              >
                                <a
                                  onClick={(openTransactionDialog)}
                                  style={{
                                    color: 'blue',
                                    cursor: 'pointer'
                                  }}
                                >
                                  {value}
                                </a>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container className={classes.fullWidth} justify='center'>
              <Typography>
                {emptyText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Transaction
        open={dialogOpen}
        setOpen={setDialogOpen}
        transactionData={transactionData}
        occasionData={occasionData}
        month={selectedMonth}
      />
    </Grid>
  );
};

export default PaymentGrid;