import keycloak from 'config/keycloak';

const logoutAfterIdle = (minutes) => {
  window.onload = function () {
    inactivityTime(minutes);
  };
};

let inactivityTime = function (minutes) {
  let time;
  window.onload = resetTimer;
  document.onmousemove = resetTimer;

  function logout() {
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  }

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, minutes * 60000);
  }
};

export default logoutAfterIdle;
