import {
  createCustomCard,
  createCustomCardDesign,
  sendCardDesigns,
} from 'components/customizeCard/CustomCardSetters';
import { makeJsonGetRequest } from 'utils/CRUDUtils';

export const getCustomCard = async (card, occasionId) => {
  const path = '/card/getCustomCardsForOccasionAndAccount';
  const params = { occasionId: occasionId, accountId: card.accountId };
  const customCards = await makeJsonGetRequest(path, params);
  return customCards[0];
};

export const getSavedCardDesigns = async (card) => {
  var params = { customCardId: card.customCardId };
  var path = '/card/getCustomCardDesigns';
  const savedCardDesigns = await makeJsonGetRequest(path, params);

  if (savedCardDesigns.length !== 0) {
    card.customCardDesigns = savedCardDesigns;
    return card;
  } else {
    return getNewCardDesigns(card);
  }
};

export const getNewCardDesigns = async (card) => {
  var params = { cardId: card.cardId };
  const newCardDesigns = await makeJsonGetRequest('/card/getDesign', params);

  //If a custom card does not exist, create one.
  if (!card.customCardId) {
    const newCustomCardId = (await createCustomCard(card)).json();
    card.customCardId = parseInt(await newCustomCardId);
  }

  const stagedDesigns = [];
  newCardDesigns.forEach((cardDesign) => {
    const design = createCustomCardDesign(card, { ...cardDesign });
    stagedDesigns.push(design);
  });
  card.customCardDesigns = stagedDesigns;
  return sendCardDesigns(card);
};

export const getMedia = (customCardId) => {
  var path = '/card/getCustomCardMedia';
  var params = { customCardId: customCardId };
  return makeJsonGetRequest(path, params);
};

export const getEnvelopeStocks = async (card) => {
  var path = '/card/getCardEnvelopeStocks';
  var params = { cardId: card.cardId };
  const envelopeStocks = await makeJsonGetRequest(path, params);
  return envelopeStocks;
};

export const getPaperStocks = async (card) => {
  var params = { cardId: card.cardId };
  const paperStocks = await makeJsonGetRequest(
    '/card/getCardPaperStocks',
    params
  );
  return paperStocks;
};
