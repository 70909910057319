import React from 'react';
import 'App.css';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import NavigationBar from 'components/base/Header';

const useStyles = makeStyles((theme) => ({
  termsText: {
      fontFamily: 'arial'
  }
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justify='space-between'
    >
      <NavigationBar />
      <Grid
      container
      direction='column'
      style={{ width: '65%', alignSelf: 'center' }}
      >
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-header'
            className={classes.termsText}
            variant='h3'
            gutterBottom
            >
            Terms of Use
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subHeaderText'
            className={classes.termsText}
            gutterBottom
            >
              Please read these Terms of Use carefully. They contain important information about your rights and obligations.<br/>
              The website is brought to you by NfoSmart, Inc. ("we" or "us" or "our"). By visiting and/or using the Website, you fully 
              and unconditionally agree to these Terms of Use. If you do not agree to these Terms of Use, please do not visit or use the 
              Website. Any offer provided by us is conditioned upon your acceptance of this Terms of Use Agreement. By using this site, 
              you represent that you lawfully consent to U.S. jurisdiction for all purposes on our site as if a resident of the U.S., 
              and have the right, authority and capacity to enter into these Terms of Use Agreement.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-1'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Changes to Terms of Use
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-1'
            className={classes.termsText}
            gutterBottom
            >
              We may revise these Terms of Use at any time by updating this page. Such changes will be effective immediately upon 
              posting. It is your responsibility to periodically visit this page to review the most current version of the Terms of Use. 
              Your continued use of the website following such change will indicate your acceptance to the Terms of Use. You may print a 
              copy of these Terms of Use Agreement for your reference.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-2'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Registration (if applicable)
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-2'
            className={classes.termsText}
            gutterBottom
            >
              When and if you set up an account and/or use the Services on the Website, you agree to: (1) provide accurate, 
              current, and complete information; (2) maintain and keep your information accurate, current, and complete; (3) 
              not impersonate any person or entity, and (4) you will not select a user name that in our sole discretion deems 
              offensive. We reserve the right to terminate your access to and use of the Website and Services if any information 
              provided by you is untrue, inaccurate, not current, or incomplete. Our use and disclosure of any information you provide 
              us is governed by our privacy policy. All activity conducted in connection with your account will be your responsibility.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-3'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Sales Tax
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-3'
            className={classes.termsText}
            gutterBottom
            >
              Please note that there is no exemption from state sales or “Use Tax” based merely on the fact that a purchase 
              is made over the Internet, by catalog, or by other remote means. The laws of these states require that a 
              consumer’s sales or ‘Use Tax” be paid annually on the appropriate tax forms. 
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-header-2'
            className={classes.termsText}
            variant='h4'
            gutterBottom
            >
              Your Conduct
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-4'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Visiting this Site
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-4'
            className={classes.termsText}
            gutterBottom
            >
              By using this site you agree not to:
              <ul>
                <li>
                  Defame, abuse, harass, stalk, threaten, or otherwise violate the rights of others, including without limitation 
                  others' privacy rights or rights of publicity.
                </li>
                <li>
                  Falsely state or otherwise misrepresent your affiliation with any person or entity, or use any fraudulent, 
                  misleading, or inaccurate email address or other contact information.
                </li>
                <li>
                  Restrict or inhibit other users from using the Services.
                </li>
                <li>
                  Violate any applicable laws, rules, or regulations.
                </li>
                <li>
                  Express or imply that any statements you make are endorsed by us.
                </li>
                <li>
                  Engage in spamming or flooding.
                </li>
                <li>
                  Access or use (or attempt to access or use) another user's content without permission.
                </li>
                <li>
                  Modify, adapt, sublicense, translate, sell, reverse engineer, decompile, or disassemble any portion of the 
                  Services or the Website.
                </li>
                <li>
                  Remove any copyright, trademark, or other proprietary rights notices contained in or displayed on any 
                  portion of the Website.
                </li>
                <li>
                  "Frame" or "mirror" any portion of the Website, or link to any page from the Website without our 
                  prior written authorization.
                </li>
                <li>
                  Order or purchase Services through the Website if you are not 18 years of age or older or have 
                  the specific permission of a parent or legal guardian.
                </li>
              </ul>
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-5'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Sharing Information
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-5'
            className={classes.termsText}
            gutterBottom
            >
              By using the Website, you agree not to upload, post, email or otherwise send or transmit any material 
              that: (1) contains viruses, worms, Trojan horses, defects, date bombs, time bombs or any other computer code, 
              files or programs designed to interrupt, destroy or limit the functionality of any computer software or 
              hardware or telecommunications equipment associated with the Website; (2) is abusive, illegal, libelous, 
              defamatory, obscene, threatening, invasive of privacy or publicity rights or otherwise objectionable or which may 
              give rise to liability or violate any law; (3) is in violation of a copyright, trademark or other intellectual 
              property or other right of any person; (4) requests personally identifiable information; or (5) contains any 
              advertisement, solicitations, chain letters, pyramid schemes, investment opportunities or other unsolicited 
              commercial communication. 
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-6'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Your Submissions
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-6'
            className={classes.termsText}
            gutterBottom
            >
              If you choose to upload or otherwise submit any material through the Website, including without 
              limitation, video photographs, images, text, graphics, sounds, data, or files to the Website, by uploading 
              such video or photographs you represent and warrant that: (1) you own or otherwise possess all necessary 
              rights with respect to your Submissions; (2) your Submissions do not and will not infringe, misappropriate, 
              use or disclose without authorization, or otherwise violate any intellectual property or proprietary rights of 
              any third party; and (3) you hereby consent to the use of your likeness, and you acknowledge you have 
              obtained the written consent, release, and/or permission of every identifiable individual who appears in a 
              Submission to use such individual's likeness, or if any such identifiable individual is under the age of 
              eighteen (18), you have obtained such written consent, release and/or permission from such individual's parent 
              or guardian (and you agree to provide to us with a copy of any such consents, releases and/or 
              permission upon our request).
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-7'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Age Limitations
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-7'
            className={classes.termsText}
            gutterBottom
            >
              You must be 18 to order or purchase Services from this site unless you have the specific permission 
              from a parent or legal guardian. If you use any chat room or other community page on the Website, 
              you must be at least thirteen (13) years old. We recommend that minors over age 13 (that is, between ages 13 
              and 18) obtain their parent's or guardian's permission before sending information about themselves to 
              anyone online. <a href='https://www.consumer.ftc.gov/articles/0012-kids-and-socializing-online'>Click here</a> for 
              tips on staying safe online.<br/>If you are a convicted sexual predator, you may not use any such Website 
              feature (chat rooms or community pages).
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-8'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Obligations & Responsibilities
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-8'
            className={classes.termsText}
            gutterBottom
            >
              You acknowledge that, by providing you with the ability to view and distribute user-generated content on 
              the Website, we are acting only as a passive conduit for such distribution, and we are not undertaking any 
              obligation or liability relating to any such content. We do not and cannot review all communications and 
              materials posted to the Website, and we are not responsible for the content of such communications and materials. 
              All such content is offered AS IS, and you view and use it at your own risk. You acknowledge and agree that we 
              may (but are not obligated to) do any of the following, at our discretion: (1) monitor and/or filter any 
              Submissions; (2) remove or refuse to send, transmit, or otherwise use any Submission (including without 
              limitation, by suspending the processing and shipping of any order related to any Submission); and/or (3) 
              disclose any Submission, and the circumstances surrounding the transmission or use thereof to any third party.<br/>
              If you become aware of misuse of the Website by any person, please contact us 
              at <a href='mailto:stephonlee@nfosmart.com'>stephonlee@nfosmart.com</a>.<br/>Because we do not control the security of 
              the Internet or other networks you use to access the Website or communicate with us, we can't be, and are 
              not responsible for, the security of information that you choose to communicate with the Website while it is 
              being transmitted. In addition, we are not responsible for any data lost during transmission.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-9'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Our Use of Information Submitted
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-9'
            className={classes.termsText}
            gutterBottom
            >
              You agree that we are free to use any comments, information, reviews, feedback, postings, materials, 
              photographs, artwork, ideas, or any other content contained in any communication you may send to us, 
              without notice, compensation or acknowledgement to you, for any purpose whatsoever, including but not limited 
              to developing, manufacturing and marketing products and services and creating, modifying or improving the 
              Website or other products or services.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-10'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Idea Submission
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-10'
            className={classes.termsText}
            gutterBottom
            >
              Any ideas and/or suggestions that you submit will be owned by us, and you hereby irrevocably assign 
              any intellectual property rights in such ideas and suggestions to us.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-11'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Order Acceptance & Payment
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-11'
            className={classes.termsText}
            gutterBottom
            >
              We reserve the right at any time after receipt of your order to either accept or decline your 
              order for any reason. We reserve the right at any time after the receipt of the order, to supply 
              less than the quantity you ordered of any item. Some circumstances under which we may cancel the order 
              may include but are not limited to 1) defects or damages found at the time of shipping, 2) errors in 
              the product description, quantity and pricing on our website or catalog (if applicable), 3) due to any 
              typographical error, (4) if an item is out of stock or no longer available, (5) we become aware of a 
              product safety concern, or (6) if a product is in violation of copyright or infringement laws or any 
              other legal issue. By placing an order, you accept and agree that the total liability of us under any 
              legal claims whatsoever, shall be limited to the amount of money you have actually paid solely and only 
              for placing the order. If your order is rejected or limited by us, your exclusive and sole remedy is 
              either (a) we will issue a credit to your credit card account in the amount charged for the cancelled 
              portion or the quantity not provided (if your credit card had been charged for the order), (b) we will 
              issue a check for the amount paid by you if payment was received check (if applicable/accepted) or 
              (c) we will not charge your credit card for the cancelled portion of the order of the quantity not 
              provided. It will be in our sole discretion how to refund, refinance, or replace an item with a 
              similar or identical product.<br/>For each product or service, you order from the website, you 
              agree to pay the price applicable for the product or service (including any sales tax, 
              surcharges, and any delivery fees for the delivery service you select).  Unless agreed upon an 
              alternative billing arrangement in writing, we will automatically bill your credit card or debit 
              card submitted as part of the order process. Once an order is made by you and we print the greeting 
              cards to fulfill the order you processed; we will not refund any money back to you. It will be our 
              sole discretion to either reprint the greeting cards or provide you a credit towards another product. 
              By using a credit or debit card you signify that you are the rightful owner of the card. We shall 
              not be responsible for inappropriate credit card use nor shall we bear the responsibility to verify 
              credit card owner. Without limiting remedies, we reserve the right to charge a late fee on all 
              past due payments equivalent to the lesser of one and a half percent (1.5%) per month on the 
              unpaid balance or the highest rate allowed by law. By ordering from us, you agree to pay for all 
              collection costs, attorneys fees, and court costs incurred in the collection of past due amounts.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-12'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Compliance
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-12'
            className={classes.termsText}
            gutterBottom
            >
              You agree to comply with all applicable laws, statutes and regulations regarding your use of 
              this Site and your purchase of products or services (if applicable) through our Site. We may, 
              in our sole discretion report actual or perceived violations of law to law enforcement or appropriate 
              authorities. If we become aware of any potential violation of the Terms of Use or our Privacy Policy, 
              we may (but are not obligated to) conduct an investigation to determine the appropriate enforcement 
              action, during which we may suspend services or terminate the account of any customer being investigated.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-13'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Termination
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-13'
            className={classes.termsText}
            gutterBottom
            >
              We reserve the right in our sole discretion to terminate or restrict your use of the Website or the 
              Services, without notice, for any or no reason, and without liability to you or any third party. You agree 
              that we shall not be liable to you or any third party for any termination of your access to the Website or 
              the Services. At our sole discretion, we may modify or remove any User Content that violates or is inconsistent 
              with these Terms of Use or their intent, that your conduct is disruptive, or you have violated the law, these 
              Terms of Use, or the rights of us or another user. We will have no liability to you for any deletion of your 
              User Content.<br/>We reserve the right in our sole discretion to terminate or restrict your use of the Website or 
              the Services, without notice, for any or no reason, and without liability to you or any third party. You agree 
              that we shall not be liable to you or any third party for any termination of your access to the Website or the 
              Services. At our sole discretion, we may modify or remove any User Content that violates or is inconsistent with 
              these Terms of Use or their intent, that your conduct is disruptive, or you have violated the law, these Terms of 
              Use, or the rights of us or another user. We will have no liability to you for any deletion of your User Content.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-14'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Trademarks
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-14'
            className={classes.termsText}
            gutterBottom
            >
              All trademarks, service marks and trade names of ours used on the Website are trademarks or 
              registered trademarks of us in the U.S. and/or other countries. They may not be used without our 
              prior express written permission. All other trademarks that appear on the Website are the property of 
              their respective owners, who may or may not be affiliated with, connected to or endorsed by us.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-15'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Copyright
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-15'
            className={classes.termsText}
            gutterBottom
            >
              The entire content of the Website, including but not limited to text, graphics and code, is our property. 
              We grant you permission to electronically copy and print hard copy portions of the Website solely for 
              your own personal, non-commercial use, provided that you do not change or delete any proprietary notices 
              from downloaded or printed materials. Any other use, including but not limited to the reproduction, 
              distribution, display or transmission of the Website content is strictly prohibited, unless authorized 
              by us in writing.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-16'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Copyright Violations
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-16'
            className={classes.termsText}
            gutterBottom
            >
              We respect the intellectual property rights of others and will take appropriate steps to protect 
              the intellectual property rights of third parties if it receives notice in accordance with the 
              Digital Millennium Copyright Act. If you believe your copyright or the copyright of another has been infringed 
              on, please provide our Copyright Agent with a written notice containing the following information:
              <ul>
                <li>
                  a physical or electronic signature of the person authorized to act on behalf of the owner of the copyright interest 
                  that you believe has been infringed;
                </li>
                <li>
                  an identification of the copyrighted work that you claim has been infringed;
                </li>
                <li>
                  a description of where the material that you claim is infringed is located on the site;
                </li>
                <li>
                  information on how to contact you including your address, telephone number, and email address, if available;
                </li>
                <li>
                  a statement by you that you have a good faith belief that the disputed use is not authorized by the 
                  copyright owner, its agent, or the law; and
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the above information in your notice is 
                  accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
                </li>
              </ul>
              Claims of infringement should be mailed via certified mail, return receipt requested, to the following address:<br/>
              Attn: Copyright Agent<br/>3074 Dunlin Lake Road<br/>Lawrenceville, GA 30044
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-17'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Warranty Disclaimer & Liability Limit
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-17'
            className={classes.termsText}
            gutterBottom
            >
              To the fullest extent permissible under applicable law, we present the Website and the Services "AS IS" and 
              without any warranty of any kind, whether express, implied, or statutory. We specifically disclaim any 
              implied warranties of title, merchantability, fitness for a particular purpose, and non-infringement. We do not 
              warrant that the functions contained in the Website will be uninterrupted or error-free, that defects will be 
              corrected or that this Website or the server that makes this website available are free of viruses or other harmful 
              components. In the event of any breach of any warranty, your exclusive remedy shall be that we shall, <u>at our option</u>, 
              repair, replace, or refund the price you paid for any defective goods. We assume no liability or responsibility 
              for any errors or omissions on the Website; any failures, delays, or interruptions in the Website's accessibility; 
              any losses or damages arising from the use of the Website; or any conduct by other users of the Website. We reserve 
              the right to deliver the Website in our sole and absolute discretion. Some jurisdictions do not allow the disclaimer 
              of implied warranties, so the foregoing may not apply to you. IN NO EVENT SHALL WE, OUR AFFILIATES, SUBSIDIARIES, 
              SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR ANY SPECIAL, 
              INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE WEBSITE OR THESE TERMS, ON 
              ANY THEORY OF LIABILITY, AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF DAMAGE ARISING OUT OF OR RELATING IN ANY 
              WAY TO THE SERVICES (INCLUDING, BUT NOT LIMITED TO, ALL MATERIALS INCORPORATED THEREIN AND ALL FEATURES AND 
              FUNCTIONALITY OF THE SERVICE) AND TOTAL LIABILITY FOR DAMAGES UNDER THIS AGREEMENT SHALL BE LIMITED TO THE TOTAL 
              AMOUNT PAID FOR THE PRODUCT. Some states do not allow the exclusion of certain damages, so the above may not apply 
              to you. If any authority holds any portion of this section to be unenforceable, then liability will be limited to 
              the fullest possible extent permitted by law.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-18'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Indemnification
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-18'
            className={classes.termsText}
            gutterBottom
            >
              You agree to indemnify, defend, and hold us, our shareholders, officers, directors, employees, and agents 
              harmless from and against any third-party claim or cause of action, including reasonable attorneys' fees and court 
              costs, arising, directly or indirectly, in whole or in part, out of your use of the Website or your violation of 
              these Terms of Use, any law or the rights of any third party.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-19'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Electronic Notices
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-19'
            className={classes.termsText}
            gutterBottom
            >
              By using the Website, you agree to receive electronic communications from us. You agree that any notice, 
              agreement, disclosure, or other communication that we send you electronically will satisfy any legal communication 
              requirements, including that such communications be in writing.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-20'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Third-Party Links
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-20'
            className={classes.termsText}
            gutterBottom
            >
              The Website may link to sites operated by third parties. However, we have no control over these 
              linked sites, all of which have their own terms of use and data collection practices. These linked 
              sites are only for your convenience, and you access them at your own risk.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-21'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Disputes
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-21'
            className={classes.termsText}
            gutterBottom
            >
              Your use of the Website shall be governed by the laws of Georgia, without regard to choice of 
              law provisions. Except where prohibited, you agree that any and all disputes, claims and causes of action 
              directly or indirectly arising out of or relating to the Website shall be resolved individually, without 
              resort to any form of class action, and exclusively in the state or federal courts located in Gwinnett County, 
              Georgia. Any cause of action or claim you may have with respect to the Website must be commenced 
              within <u>ninety (90) days</u> or the least number of days the law allows, after the claim or cause of action arises, 
              or it shall be forever barred.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-22'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              General
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-22'
            className={classes.termsText}
            gutterBottom
            >
              The Services are controlled and operated within the United States, and are not intended to be subject 
              to the laws or jurisdiction of any country or territory other than that of the United States. We do 
              not represent or warrant that the Services or any part thereof are appropriate or available for use in 
              any particular jurisdiction. Those who choose to access the Services do so on their own initiative and at 
              their own risk, and are responsible for complying with all local laws, rules, and regulations. We may 
              limit the Services availability, in whole or in part, to any person, geographic area or jurisdiction we 
              choose, at any time and in our sole discretion.<br/>If any provision of these Terms of Use is held to be 
              invalid or unenforceable, such provision shall be struck, and the remaining provisions shall be enforced. 
              Headings are for reference purposes only and in no way define, limit, construe or describe the extent or 
              scope of such provision. Our failure to enforce any provision of these Terms of Use shall not constitute a 
              waiver of that or any other provision. These Terms of Use set forth the entire understanding and agreement 
              between you and us with respect to the subject matter hereof.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subheader-23'
            className={classes.termsText}
            variant='h5'
            gutterBottom
            >
              Contact Us
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtext-23'
            className={classes.termsText}
            gutterBottom
            >
              If you have any questions about these Terms of Use, please contact us at: <a href='mailto:stephonlee@nfosmart.com'>nfosmart@gmail.com</a>.
              <br/>NfoSmart, Inc.<br/>3074 Dunlin Lake Road<br/>Lawrenceville, Georgia 30044
            </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
