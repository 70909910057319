import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { makeFileGetRequest } from 'utils/CRUDUtils';

const useStyles = makeStyles(() => ({
  tableStyle: {
    minHeight: 800,
    maxWidth: '75%'
  },
  buttonStyle: {
    textTransform: 'none'
  }
}));

const PdfGrid = ({ batches }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const renderButton = (params) => {
    const onClick = (e) => {
      fetchData(params.row.pdfUrl, params.row.batchFile);
    };

    return <Button onClick={onClick} className={classes.buttonStyle}>Download</Button>;
}

async function fetchData(url, fileName) {
  const params = { pdfUrl:url };
  const pdfFile = await makeFileGetRequest('/administration/batchPDF', params)
  const blob = await pdfFile.blob();
  const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
  const link = document.createElement("a");
  link.download = fileName;
  link.href = blobUrl;
  link.click();
}

  const gridColumns = [
    { field: 'batchDate', headerName: 'Batch Date', flex: 2 },
    { field: 'accountId', headerName: 'Account Id', flex: 3 },
    { field: 'batchFile', headerName: 'Batch File', flex: 2 },
    { field: "download", headerName: "Download", sortable: false, renderCell: renderButton, flex: 1 }
  ];

  useEffect(() => {
    if (batches.length) {
      createBatchColumns();
    }

    async function createBatchColumns() {
      for (const batch of batches) {
        setRows((prevRows) => {
          const newArr = [...prevRows];
          newArr.push({
            id: batch.url,
            pdfUrl: batch.url,
            batchDate: batch.batchDate,
            accountId: batch.accountId,
            batchFile: batch.fileName
          });
          return newArr;
        });
      }
    }
  }, [batches]);

  return (
    <Grid container id='pdf-grid' className={classes.tableStyle} justifyContent='center' alignItems='stretch' direction='column'>
        <Grid item justify='left'>
            <Typography
              variant='h3'
              align='left'
              className={classes.greeting}
              color='primary'
            >
              <b>PDF Files</b>
            </Typography>
          </Grid>
        <DataGrid
            rows={rows}
            columns={gridColumns}
            initialState={{
                sorting: {
                  sortModel: [{ field: 'batchDate', sort: 'asc' }],
                },
              }}
        />
    </Grid>
  );
};

export default PdfGrid;
