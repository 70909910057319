import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core';
import { mapRecipientStatusesToOccasions } from 'components/scheduleCard/ScheduleUtils';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  tableContainer: {
    maxHeight: 300,
  },
  tableCell: {
    fontSize: '1.1rem',
  },
}));

const MasterSchedule = ({ savedCards }) => {
  const classes = useStyles();
  const monthWithCards = useMemo(() => new Map(), []);
  const [hasData, setHasData] = useState(false);

  const columns = [
    { id: 'occasion', label: 'Occasion' },
    { id: 'total', label: 'Total Recipients' },
    { id: 'scheduled', label: 'Scheduled' },
    { id: 'paid', label: 'Paid' },
    { id: 'printed', label: 'Printed' }
  ];
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (savedCards.length) {
      getRecipientCountsForCard();
    }

    async function getRecipientCountsForCard() {
      const dateRanges = getNextMonths();
      await mapRecipientStatusesToOccasions(dateRanges, savedCards, monthWithCards, setRows);

      for (const [key, value] of monthWithCards) {
        if (value.total) {
          setHasData(true);
        }
        setRows((prevRows) => {
          const newArr = [...prevRows];
          newArr.push({
            occasion: key,
            total: value.total ? value.total : '-',
            scheduled: value.scheduled ? value.scheduled : '-',
            paid: value.paid ? value.paid : '-',
            printed: value.printed ? value.printed : '-'
          });
          return newArr;
        });
      }
    }

    function getNextMonths() {
      const dateRanges = [];
      const monthsAhead = 12;
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      for (var i = 0; i < monthsAhead; i++) {
        const month = currentDate.getMonth() + i;
        const firstDay = new Date(year, month, 1).toISOString();
        const lastDay = new Date(year, month + 1, 0).toISOString();
        dateRanges.push({ firstDay: firstDay, lastDay: lastDay });
      }
      return dateRanges;
    }
  }, [savedCards, monthWithCards]);

  return (
    <Grid container item style={{ flex: 1 }} id='schedules-grid' className={classes.fullWidth}>
      <Accordion
        className={classes.fullWidth}
        style={{ border: 'solid grey', borderRadius: '25px' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h5' align='center' style={{ width: '100%' }} color='primary'>
            View Schedule
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {hasData ? (
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                aria-label='sticky table'
                style={{
                  borderRight: '2px solid black',
                  borderLeft: '2px solid black',
                  borderBottom: '2px solid black',
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={column.id}
                        size='small'
                        className={classes.tableCell}
                        style={{
                          borderTop: '2px solid black',
                          borderBottom: '2px solid black',
                          borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                        }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        role='checkbox'
                        key={'row-' + index}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                        }}
                      >
                        {columns.map((column, i) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              size='small'
                              className={classes.tableCell}
                              style={{
                                borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                              }}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid container className={classes.fullWidth} justify='center'>
              <Typography>
                No schedule currently exists. Create a new card to enable this feature.
              </Typography>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default MasterSchedule;
