import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import DragAndDrop from 'components/sendCard/DragAndDrop';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { makeGetRequest } from 'utils/CRUDUtils';

const useStyles = makeStyles((theme) => ({
  downloadLink: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const NoRecipientsDisplay = ({ handleDrop }) => {
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      const demoVideoUrl = await makeGetRequest('/media/getExampleVideoUrl');
      document.getElementById('csv-demo-video').src = demoVideoUrl;

      const sampleFile = await fetch('/media/getSampleFile');
      const blob = await sampleFile.blob();
      const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
      document.getElementById('sample-file-link').href = url;
    }
    fetchData();
  }, []);

  return (
    <Grid container item justify='center' spacing={2}>
      <Grid container item justify='center'>
        <DragAndDrop handleDrop={handleDrop}>
          <Grid
            container
            direction='column'
            justify='space-evenly'
            alignItems='center'
            style={{ height: '200px' }}
            className='drag-and-drop'
            id='csv-drop-off-main'
          >
            <Grid item justify='center'>
              <Typography id='recipient-status-msg' variant='h6' align='center'>
                You have no recipients. Please upload your file.
              </Typography>
              <Typography id='add-recipients-directions' variant='body2' align='center'>
                {'Drag & drop'} a <b>.csv</b> file here, or click the <br /> Add Recipients icon.
              </Typography>
            </Grid>
            <Grid item direction='column' justify='center' alignItems='center'>
              <InsertDriveFileOutlinedIcon
                id='file-icon'
                style={{
                  fontSize: '2.5rem',
                  display: 'block',
                  margin: 'auto',
                }}
              />
              <Button
                id='sample-file-link'
                download={'ExampleRecipients.csv'}
                disableRipple
                href='#'
                classes={{ root: classes.downloadLink }}
              >
                <Typography id='sample-file-text' align='center'>
                  Download sample file
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DragAndDrop>
      </Grid>
      <Grid item>
        <video id='csv-demo-video' width='400px' height='auto' controls src='' />
        <Typography id='video-description' align='center'>
          Watch this video for guidance on Excel.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoRecipientsDisplay;
