const { createMuiTheme } = require('@material-ui/core');

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#31539a',
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    fontWeightRegular: 400,
    h3: {
      fontSize: '2.75rem',
    },
  },
});

export default THEME;
