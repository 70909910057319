import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { makeGetRequest } from 'utils/CRUDUtils';
import keycloak from 'config/keycloak';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  dialogButton: {
    width: '80%',
  },
}));

export default function HamburgerMenu({
  existingCards,
  cardsWithSchedules,
  selectCard,
  selectOccasion,
  setCustomCardId,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [canUpdatePaymentType, setCanUpdatePaymentType] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setCardScheduleInfo = (occasion) => {
    selectCard(existingCards[occasion].card);
    selectOccasion(occasion);
    setCustomCardId(existingCards[occasion].customCardId);
  };

  useEffect(() => {
    async function getPaymentType() {
      const path = '/payment/getPaymentType';
      const params = {};
      const paymentType = await makeGetRequest(path, params);
      if (paymentType === 'CreditCard') {
        setCanUpdatePaymentType(true);
      } else {
        setCanUpdatePaymentType(false);
      }
    }
    getPaymentType();
  }, []);

  return (
    <div>
      <IconButton
        aria-label='menu'
        aria-controls='app-menu'
        aria-haspopup='true'
        edge='start'
        className={classes.menuButton}
        color='inherit'
        onClick={handleMenu}
      >
        <MenuIcon id='menu-icon' />
      </IconButton>
      <Menu
        id='app-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem id='app-menu-dashboard' onClick={handleClose} component={Link} to='/home'>
          Dashboard
        </MenuItem>
        <MenuItem
          id='app-menu-schedule'
          onClick={() => {
            handleClose();
            setDialogOpen(true);
          }}
        >
          Schedules
        </MenuItem>
        <MenuItem id='app-menu-history' onClick={handleClose} component={Link} to='/history'>
          Card Billing and History
        </MenuItem>
        {canUpdatePaymentType ? 
        <MenuItem
          id='app-menu-updatedCard'
          onClick={() => {
            window.location = 'https://nfosmart.securepayments.cardpointe.com/pay?cf_hidden_KeycloakId='+ keycloak.subject + '&total=0.01';
            handleClose();
          }}
        >
          Update Payment Info
        </MenuItem>
        : <></>
        }
      </Menu>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='sm'>
        <DialogTitle id='dialog-title'>Which schedule would you like to view?</DialogTitle>
        <DialogContent style={{ marginBottom: '20px' }}>
          <Grid
            container
            direction='column'
            justify='space-around'
            alignItems='center'
            style={{ height: '150px' }}
          >
            {cardsWithSchedules.map((occasion) => (
              <Link
                component={Button}
                variant='outlined'
                color='primary'
                to='/schedule'
                onClick={async () => {
                  setCardScheduleInfo(occasion);
                  setDialogOpen(false);
                }}
                className={classes.dialogButton}
              >
                {occasion}
              </Link>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
