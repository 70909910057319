import { connect } from 'react-redux';
import selectOccasion, { selectCard, setCustomCardId } from 'redux/actions/CardActions';
import HamburgerMenu from 'components/base/HamburgerMenu';

const mapStateToProps = (state) => {
  return {
    cardsWithSchedules: state.cardsWithSchedules,
    existingCards: state.existingCards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCustomCardId: (id) => dispatch(setCustomCardId(id)),
  selectCard: (card) => dispatch(selectCard(card)),
  selectOccasion: (occasion) => dispatch(selectOccasion(occasion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
