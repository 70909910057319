export const cardTasks = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_OCCASION':
      return {
        ...state,
        [action.occasion.freq]: {
          ...state[action.occasion.freq],
          [action.occasion.occasion]: [],
        },
      };
    case 'ADD_TASK':
      return {
        ...state,
        [action.occasion.freq]: {
          ...state[action.occasion.freq],
          [action.occasion.occasion]: tasks(
            [...state[action.occasion.freq][action.occasion.occasion]],
            action
          ),
        },
      };
    case 'COMPLETE_TASK':
      return {
        ...state,
        [action.occasion.freq]: {
          ...state[action.occasion.freq],
          [action.occasion.occasion]: tasks(
            [...state[action.occasion.freq][action.occasion.occasion]],
            action
          ),
        },
      };
    case 'UNDO_TASK':
      return {
        ...state,
        [action.occasion.freq]: {
          ...state[action.occasion.freq],
          [action.occasion.occasion]: tasks(
            [...state[action.occasion.freq][action.occasion.occasion]],
            action
          ),
        },
      };
    default:
      return state;
  }
};

export const tasks = (state, action) => {
  switch (action.type) {
    case 'ADD_TASK':
      return [
        ...state,
        {
          id: action.task,
          completed: false,
        },
      ];
    case 'COMPLETE_TASK':
      return state.map((task) =>
        task.id === action.id ? { ...task, completed: true } : task
      );
    case 'UNDO_TASK':
      return state.map((task) =>
        task.id === action.id ? { ...task, completed: false } : task
      );
    default:
      return state;
  }
};

export default cardTasks;
