import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const StockSelector = (props) => {
  const classes = useStyles();
  const stockName = props.stockName;
  const [stock, setStock] = useState(props.initial);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setStock(event.target.value);
    props.setChosen(stock);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ display: 'none' }}>
      <FormControl className={classes.formControl}>
        <InputLabel id={stockName + '-selector-label'}>{stockName}</InputLabel>
        <Select
          labelId={stockName + '-selector-label'}
          id={stockName + '-selector'}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={stock}
          onChange={handleChange}
        >
          {props.availableStocks.map((stock) => (
            <MenuItem value={stock[stockName.toLowerCase() + 'StockId']}>
              {stock[stockName.toLowerCase() + 'Stock']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default StockSelector;
