import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    background: 'none',
  },
});

const PaneStepper = (props) => {
  const classes = useStyles();

  return (
    <MobileStepper
      id='dot-tracker'
      variant='dots'
      steps={4}
      position='static'
      activeStep={props.activePane}
      classes={{ root: classes.root }}
    />
  );
};

export default PaneStepper;
