import { connect } from 'react-redux';
import RecipientSelector from 'components/sendCard/RecipientSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    occasionName: state.currentCard.occasion,
    func: ownProps.func,
    customCardId: state.currentCard.customCardId,
  };
};

export default connect(mapStateToProps)(RecipientSelector);
