import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { getNumericMonthDayYearFromTimestamp } from 'utils/DateUtils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%'
  },
  tableContainer: {
    maxHeight: 300
  },
  tableCell: {
    fontSize: '1.1rem',
    textAlign: 'center'
  },
}));

const Transaction = ({ open, setOpen, transactionData, occasionData, month }) => {
  const classes = useStyles();
  const columns = [
    { id: 'date', label: 'Date' },
    { id: 'transactionId', label: 'Transaction Id' },
    { id: 'occasion', label: 'Occasions' },
    { id: 'cardCount', label: 'Card Count' },
    { id: 'cardCost', label: 'Card Cost' },
    { id: 'salesTax', label: 'Sales Tax' },
    { id: 'stampCost', label: 'Stamp Cost' },
    { id: 'totalCost', label: 'Total Cost' }
  ];
  const rows = [];

  if (transactionData && occasionData && month) {
    const transactionMap = transactionData.get(month);
    const sortedMap = new Map([...transactionMap.entries()].sort((a, b) => new Date(a[1][0].createdOn) - new Date(b[1][0].createdOn)));
    for (const [key, value] of sortedMap) {
      const transaction = value[0];
      const occasionMap = occasionData.get(month).get(key);
      let occasions = '';
      let occasionCount = '';
      for (const [occasionKey, occasionValue] of occasionMap) {
        occasions = occasions + occasionKey + '\n';
        occasionCount = occasionCount + occasionValue + '\n';
      }

      rows.push({
        date: getNumericMonthDayYearFromTimestamp(transaction.createdOn),
        transactionId: transaction.orderId,
        occasion: occasions,
        cardCount: occasionCount,
        cardCost: '$' + transaction.cardCost.toFixed(2),
        salesTax: '$' + transaction.salesTaxCost.toFixed(2),
        stampCost: '$' + transaction.stampCost.toFixed(2),
        totalCost: '$' + (transaction.cardCost + transaction.salesTaxCost + transaction.stampCost).toFixed(2)
      });
    }
  }

  return (
    <Dialog open={open} maxWidth='lg'>
      <DialogTitle id='transaction-dialog-title' disableTypography>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6'>Transactions</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label='sticky table'
            style={{
              borderRight: '2px solid black',
              borderLeft: '2px solid black',
              borderBottom: '2px solid black',
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={column.id}
                    size='small'
                    className={classes.tableCell}
                    style={{
                      borderTop: '2px solid black',
                      borderBottom: '2px solid black',
                      borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    role='checkbox'
                    key={'row-' + index}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                    }}
                  >
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          size='small'
                          className={classes.tableCell}
                          style={{
                            borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                          }}
                        >
                          <span style={{ whiteSpace: "break-spaces" }}>{value}</span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default Transaction;
