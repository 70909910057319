import React from 'react';
import 'App.css';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import NavigationBar from 'components/base/Header';

const useStyles = makeStyles((theme) => ({
  privacyText: {
      fontFamily: 'arial'
  }
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justify='space-between'
    >
      <NavigationBar />
      <Grid
      container
      direction='column'
      style={{ width: '65%', alignSelf: 'center' }}
      >
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-header'
            className={classes.privacyText}
            variant='h3'
            gutterBottom
            >
            Privacy Policy
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subheader-1'
            className={classes.privacyText}
            variant='h5'
            gutterBottom
            >
              General Overview
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subtext-1'
            className={classes.privacyText}
            gutterBottom
            >
              Nfosmart is committed to protecting the privacy and security of all personal information that we process to provide services 
              to our clients.<br/>Nfosmart's clients, employees, vendors, partners, and visitors provide us with personal information, 
              and protecting the privacy and security of this personal information is critical to Nfosmart.<br/>This policy helps us ensure 
              that personal information is handled properly. The Privacy Policy governs personal information collected by Nfosmart for 
              its own purposes as well as information provided to us for our greeting cards or payment services, or any of our software 
              applications for our clients. The Privacy Policy concerns the protection of information collected online as well as 
              offline.<br/>Nfosmart is also committed to transparency. We want everyone to understand how we collect and use personal 
              information so they can interact with Nfosmart with confidence.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subheader-2'
            className={classes.privacyText}
            variant='h5'
            gutterBottom
            >
              Nfosmart Privacy Policy
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subtext-2'
            className={classes.privacyText}
            gutterBottom
            >
              Nfosmart, Inc., and its employees, contractors, managers, officers, directors, divisions, branches, subsidiaries, and 
              controlled affiliates (collectively, “Nfosmart”) respect the privacy of all Personal Data it holds and is committed 
              to protecting and limiting the use of such information in accordance with applicable data protection and privacy 
              laws wherever it does business.<br/>“Personal Data” means any information relating to an identified or identifiable 
              natural person. Consequently, Nfosmart has adopted this Privacy Policy (the “Policy”) to protect the individuals 
              whose Personal Data Nfosmart controls or otherwise processes.<br/>The term <b>“processing”</b> is used in this Policy to 
              cover all activities involving Personal Data, including, but not limited to: collecting, handling, updating, 
              storing, deleting, sharing, accessing, using, transferring, and disposing of the Personal Data. This Policy reflects 
              principles and standards on handling Personal Data. This Policy governs all Nfosmart business activity and the 
              conduct of all Nfosmart’s employees, contractors, representatives and third parties with respect to Personal Data 
              processed on behalf of Nfosmart at any location globally.<br/>It is Nfosmart’s policy and practice to comply with 
              all applicable data protection and privacy laws wherever it does business. In the event an applicable data 
              protection or privacy law requires any action or imposes any standard more stringent than this Policy, the 
              requirements of the law shall control and take precedence over the requirements of this Policy.
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subheader-3'
            className={classes.privacyText}
            variant='h5'
            gutterBottom
            >
              Personal Data Covered by this Policy
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subtext-3'
            className={classes.privacyText}
            gutterBottom
            >
              All Personal Data processed by Nfosmart for any purpose MUST be processed in compliance with this Policy. 
              Nfosmart may obtain such Personal Data directly from the individual (“data subject”), for example through 
              website registrations, or indirectly through employees and third parties, for example, through contact information. 
              Nfosmart SHALL process some Personal Data on its own behalf for its own business purposes. Personal Data that 
              is Company Controlled Personal Data will often, but not always, be collected directly by Nfosmart. Company 
              Controlled Personal Data is controlled and processed by Nfosmart related to the collection of accounts receivable, 
              the processing of accounts payable, sales, marketing, and vendor and customer relationship management purposes.<br/>
              Nfosmart SHALL process some Personal Data solely on behalf of its clients while delivering Nfosmart services 
              (“Services Personal Data”).  Nfosmart SHALL process Services Personal Data to accomplish the business purposes 
              of the client for whom the services are provided, and often will not have a direct relationship with the subject 
              of the Services Personal Data. Typically, Services Personal Data will have been generated or collected by the 
              client and provided to Nfosmart for processing, but that is not always the case. In some cases, Nfosmart may not 
              even know that the data it processes for a client includes Services Personal Data. Nfosmart SHALL process 
              Services Personal Data exclusively pursuant to contractual obligations, client’s instruction, its Record 
              Retention Policy, and/or regulatory purposes, and SHALL be required to return the Services Personal Data to 
              the client, or to destroy it, after it is no longer needed in accordance with those contractual obligations. 
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subheader-4'
            className={classes.privacyText}
            variant='h5'
            gutterBottom
            >
              Standards Applicable to the Processing of Personal Data
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subtext-4'
            className={classes.privacyText}
            gutterBottom
            >
              The following standards MUST be applied by the employees, contractors, representatives, and third parties 
              acting on behalf of each affiliated Nfosmart entity covered by this Policy with respect to Personal Data that 
              is processed by Nfosmart:
              <ul>
                <li>
                  Fairness. Nfosmart SHALL process Personal Data fairly and lawfully.
                </li>
                <li>
                  Limitation on Purpose. Nfosmart SHALL process Personal Data only in support of legitimate Nfosmart 
                  business purposes that are specified and explicit or apparent from the circumstances. Services Personal 
                  Data MUST not be processed by Nfosmart for any purpose other than the delivery of the services to be provided 
                  by Nfosmart in accordance with the client contract governing such data or another purpose authorized or 
                  instructed by the client who provided the Services Personal Data to Nfosmart for processing. 
                </li>
                <li>
                  Data Quality and Proportionality. Nfosmart SHALL endeavor to verify the Company Controlled Personal Data 
                  it processes is accurate and where necessary, is kept current.  Nfosmart SHALL endeavor to verify the 
                  Company Controlled Personal Data is adequate, relevant, and not excessive in relation to the purposes for 
                  which it is collected and/or processed. For Services Personal Data, Nfosmart's client is responsible for 
                  confirming the data is accurate, current, adequate, relevant, and not excessive.
                </li>
                <li>
                  Transparency. Individuals who are the subjects of Company Controlled Personal Data will be provided with 
                  information necessary to verify fair processing of their Personal Data.
                </li>
                <li>
                  Sensitive Personal Data. Company Controlled Personal Data and Services Personal Data that reveal the 
                  racial or ethnic origin, political opinions, religious or other beliefs of a similar nature, trade union 
                  membership, physical or mental health condition, genetic or biometric data, sexual life or sexual 
                  orientation, commission of any offense or criminal record of the individual that is the subject of the 
                  Personal Data SHALL always be classified as Sensitive Personal Data for purposes of this Policy. Such data 
                  MAY require additional layers of security or varying legal bases, such as express consent of data subject. 
                  Company Controlled Personal Data relating to children and to the financial history or circumstances of the 
                  subject of the Personal Data may be classified as Sensitive Personal Data under some applicable law. Data 
                  that is Sensitive Personal Data MUST not be processed without the consent of the subject of the Personal 
                  Data if such consent is required by applicable law. Prior to collecting or otherwise processing data that 
                  is Sensitive Personal Data, the lawfulness of such collection or processing SHALL be verified by 
                  consultation with the Nfosmart Legal Department. 
                </li>
                <li>
                  Data Subject Rights. Where Nfosmart is required by law or regulation to provide data subjects with rights 
                  over their data, then Nfosmart SHALL enable these rights in accordance with said law or regulation. 
                </li>
                <li>
                  Personal Data used for Marketing Purposes. Where Company Controlled Personal Data is processed for the purpose 
                  of marketing and analytics, effective procedures exist allowing the subject of the Personal Data to opt out 
                  from such use. The opt-out option refers to the marketing of consumer or commercial goods or services to an 
                  individual data subject and SHALL not limit normal and customary communications by or on behalf of Nfosmart 
                  regarding the individual’s relationship with Nfosmart. 
                </li>
                <li>
                  Data Security. Appropriate physical network and process security measures designed to protect Personal 
                  Data or Sensitive Personal Data processed by Nfosmart against accidental or unlawful destruction, accidental 
                  loss, alteration, or unauthorized disclosure or access are in place. 
                </li>
                <li>
                  Data Access. Nfosmart SHALL take reasonable steps to determine who gains access to Personal Data. 
                  The Enterprise Identity and Access Management Policy is based on the “Principle of Least Privilege,” 
                  and access to Personal Data shall be limited by that principle. The Principle of Least Privilege requires 
                  that privileged access must be provisioned with the minimum level of access to non-public data which is 
                  required to satisfy a user’s job responsibilities. This premise is in addition to this Policy as well as 
                  other Nfosmart policies, as applicable. 
                </li>
                <li>
                  Data Transfers. Personal Data MUST not be transferred across any political or geographic boundary unless 
                  such cross-border data flow is authorized by agreement of the individual that is the subject of the 
                  Personal Data or the transfer is otherwise permitted by applicable laws. Any third party authorized by 
                  Nfosmart to process Company Controlled Personal Data on behalf of Nfosmart MUST first agree by written 
                  contract to (i) respect and maintain the confidentiality and security of such Personal Data in accordance 
                  with standards that meet the requirements of this Policy, (ii) to process such Personal Data pursuant to 
                  Nfosmart instructions, and (iii) to return, or delete the Company Controlled Personal Data, as directed 
                  by Nfosmart, when it is no longer needed for the purposes for which it was provided. 
                </li>
                <li>
                  Obsolete Personal Data. Nfosmart SHALL not retain Personal Data longer than necessary to accomplish the 
                  legitimate business purpose for which the Personal Data was collected and processed by Nfosmart or as 
                  required by the terms of a client contract or applicable law. Such obsolete Personal Data, and the media on 
                  which it is contained, MUST be destroyed in a secure manner or, where appropriate, returned to a client. 
                </li>
                <li>
                  Disputes or Objections. Nfsomart SHALL address any complaints or disputes regarding Personal 
                  Data to settle in a timely fashion. 
                </li>
                <li>
                  Nfosmart provides employees and contractors with Information Security and Privacy Awareness Training, 
                  which includes specific education on personal data protection, compliance, and risk management topics. 
                  Privacy training is provided annually to all employees and contractors. 
                </li>
              </ul>
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subheader-5'
            className={classes.privacyText}
            variant='h5'
            gutterBottom
            >
              Communication
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='privacy-subtext-5'
            className={classes.privacyText}
            gutterBottom
            >
              We can be reached at <a href='mailto:privacy@nfosmart.com'>privacy@nfosmart.com</a>. When communicating with us, 
              please provide your first and last name along with your contact information and brief description of your 
              concern. This will help us to reply to your question or concern promptly.<br/><br/>Modified on 5-23-2022
            </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
