import { connect } from 'react-redux';
import Previewer from 'components/customizeCard/Previewer';
import { setCustomCardId } from 'redux/actions/CardActions';

const mapStateToProps = (state, ownProps) => {
  return {
    localCard: ownProps.localCard,
    occasionId: state.occasionProps[state.currentCard.occasion],
    orientation: state.currentCard.orientation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCustomCardId: (id) => dispatch(setCustomCardId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Previewer);
