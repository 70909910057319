import React from 'react';
import { Grid, makeStyles, Step, Stepper, StepLabel, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CARD_TASK } from 'constants/Cards';
import VisibleCardSelector from 'components/chooseCard/containers/VisibleCardSelector';
import { VisibleStepContent } from 'components/base/containers/VisibleCardStepper';
import VisibleCustomizer from 'components/customizeCard/containers/VisibleCustomizer';
import VisibleRecipientSelector from 'components/sendCard/containers/VisibleRecipientSelector';
import VisibleScheduler from 'components/scheduleCard/containers/VisibleScheduler';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 30,
    paddingBottom: 30,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const StepContent = (props) => {
  switch (props.activeStep) {
    case 0:
      return props.index === 0 ? (
        <VisibleCardSelector func={props.func} />
      ) : (
        <VisibleCustomizer func={props.func} />
      );
    case 1:
      return <VisibleRecipientSelector func={props.func} />;
    case 2:
      return <VisibleScheduler func={props.func} />;
    default:
      return 'Unknown stepIndex';
  }
};

const CardStepper = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const steps = CARD_TASK.STEPS;
  const routes = CARD_TASK.ROUTES;
  const activeStep = props.activeStep;

  const handleNext = () => {
    if (props.index + 1 === routes.length) {
      history.push('/home');
    } else {
      history.push(routes[props.index + 1]);
    }
    if (props.lastSubTask) {
      props.completeTask(steps[activeStep]);
    }
  };

  const handleBack = () => {
    history.push(routes[props.index - 1]);
    if (props.checkpoint) props.undoTask(steps[activeStep - 1]);
    if (props.lastSubTask) props.undoTask(steps[activeStep]);
  };

  return (
    <Grid container direction='column' className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel id={'steplabel-' + label}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <div>
          <Typography className={classes.instructions}>All steps completed</Typography>
        </div>
      ) : (
        <Grid container justify='center' style={{ flex: 1 }}>
          <VisibleStepContent
            activeStep={activeStep}
            func={{ handleNext, handleBack }}
            index={props.index}
          />
        </Grid>
      )}
    </Grid>
  );
};

CardStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  toggleCompleteTask: PropTypes.func.isRequired,
};

export default CardStepper;
