import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 410,
  },
});

const RecipientsForCurrentMonthGrid = ({ recipients }) => {
  const classes = useStyles();

  const columns = [
    { id: 'lastName', label: 'Last Name', minWidth: 100 },
    { id: 'firstName', label: 'First Name', minWidth: 100 },
    { id: 'city', label: 'City' },
    { id: 'occasionDate', label: 'Occasion Date' },
  ];

  return (
    <Grid container item style={{ flex: 1, marginTop: '20px' }}>
      <Paper id='recipient-current-month-grid' className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      border: '2px solid black',
                      borderRight: 'none',
                    }}
                    size='small'
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {recipients.map((row, index) => {
                return (
                  <TableRow
                    role='checkbox'
                    key={'row-' + index}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          size='small'
                          style={{ borderLeft: '2px solid black' }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default RecipientsForCurrentMonthGrid;
