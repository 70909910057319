export const addFieldHtmlToEditorCanvas = (data) => {
  document.getElementById('editor-canvas').insertAdjacentHTML('afterbegin', data);
};

export const setNewToolbar = (toolbar) => {
  document.getElementById('quill-tools-container').innerHTML = toolbar;
};

export const getQuillsInSaveableFormat = (index, div, quills) => {
  const editorHtml = document.getElementById('editor-' + index).outerHTML;
  div.insertAdjacentHTML('beforeend', editorHtml.trim());

  var editorContainer = div.lastChild;
  clearContents(editorContainer);

  const editableFieldHtml = quills[index].root.innerHTML;
  editorContainer.innerHTML = editableFieldHtml;
};

export const transferEditsToHiddenCanvas = (hiddenCanvas) => {
  var pdfDiv = document.createElement('div');
  const editorHtml = document.getElementById('editor-container').outerHTML;
  pdfDiv.innerHTML = editorHtml;
  const editorContainer = pdfDiv.firstChild;
  const editorCanvas = editorContainer.lastChild;
  const quills = editorCanvas.childNodes;

  for (var i = 0; i < quills.length; i++) {
    quills[i].removeChild(quills[i].lastChild);
  }

  hiddenCanvas.insertAdjacentHTML('afterbegin', editorContainer.outerHTML);
};

export const removeOutlineFromEdits = () => {
  const editableFields = document.getElementsByClassName('ql-editable');
  const fieldCount = editableFields.length;
  for (var n = 0; n < fieldCount / 2; n++) {
    editableFields[editableFields.length - 1].classList.remove('ql-editable');
  }
};

export const removeBlankQuills = () => {
  const allQuills = document.getElementsByClassName('ql-editor');
  for (var i = 0; i < allQuills.length; i++) {
    allQuills[i].removeAttribute('data-html2canvas-ignore');
  }

  const blankQuills = document.getElementsByClassName('ql-blank');
  for (var j = 0; j < blankQuills.length; j++) {
    blankQuills[j].setAttribute('data-html2canvas-ignore', 'true');
  }
};

export const scaleEditsToCanvas = (hiddenCanvas) => {
  const editorCanvases = document.getElementsByClassName('editor-canvas');
  var scale = hiddenCanvas.clientWidth / document.getElementById('pane-preview').clientWidth;

  const hiddenEditorCanvas = editorCanvases[editorCanvases.length - 1];
  hiddenEditorCanvas.style.transform = 'scale(' + scale + ')';
  hiddenEditorCanvas.style.maxWidth = '100%';

  const editableFields = document.getElementsByClassName('ql-container');
  const fieldCount = editableFields.length;
  const editableFieldWidth = editableFields[0].offsetWidth;
  for (var i = 0; i < fieldCount; i++) {
    editableFields[i].style.removeProperty('min-width');
    editableFields[i].style.width = editableFieldWidth + 'px';
  }
};

export const clearHiddenCanvas = (hiddenCanvas) => {
  hiddenCanvas.removeChild(hiddenCanvas.firstChild);
};

export const clearContents = (container) => {
  while (container.firstChild) {
    container.removeChild(container.lastChild);
  }
};

export const hasActivatedQuills = () => {
  return !document.getElementById('quill-tools-container').hasChildNodes();
};
