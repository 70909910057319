import { combineReducers } from 'redux';
import { cardTasks } from 'redux/reducers/CardTaskReducer';
import { currentCard, occasionProps } from 'redux/reducers/CurrentCardReducer';
import { existingCards, cardsWithSchedules } from 'redux/reducers/ExistingCardReducer';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  cardTasks,
  occasionProps,
  currentCard,
  existingCards,
  cardsWithSchedules,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
