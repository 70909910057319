import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { getFullFormattedDateTime } from 'utils/DateUtils';

const useStyles = makeStyles(() => ({
  tableStyle: {
    minHeight: 800,
    maxWidth: '75%'
  }
}));

const AccountGrid = ({ activeAccounts }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);

  const gridColumns = [
    { field: 'accountId', headerName: 'Account ID', width: 110 },
    { field: 'accountType', headerName: 'Account Type', width: 120 },
    { field: 'firstName', headerName: 'First Name', width: 120 },
    { field: 'lastName', headerName: 'Last Name', width: 120 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'company', headerName: 'Company', width: 370 },
    { field: 'createdOn', headerName: 'Created On', width: 200 },
    { field: 'updatedOn', headerName: 'Last Updated On', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 110 },
    { field: 'address', headerName: 'Address', width: 290 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 120 },
    { field: 'zip', headerName: 'Zip Code', width: 100 },
    { field: 'keycloakId', headerName: 'Keycloak ID', width: 300 },
    { field: 'cardpointeProfileId', headerName: 'CardPointe Profile ID', width: 190 }
  ];

  useEffect(() => {
    if (activeAccounts.length) {
      createAccountColumns();
    }

    async function createAccountColumns() {
      for (const account of activeAccounts) {
        setRows((prevRows) => {
          const newArr = [...prevRows];
          newArr.push({
            id: account.accountId,
            accountId: account.accountId,
            keycloakId: account.keycloakId,
            cardpointeProfileId: account.cardpointeProfileId,
            email: account.email,
            firstName: account.firstName,
            lastName: account.lastName,
            company: account.company,
            createdOn: getFullFormattedDateTime(account.createdOn),
            updatedOn: getFullFormattedDateTime(account.updatedOn),
            phone: account.phone,
            address: account.address,
            city: account.city,
            state: account.state,
            zip: account.zip,
            accountType: account.accountType.accountType
          });
          return newArr;
        });
      }
    }
  }, [activeAccounts]);

  return (
    <Grid container id='accounts-grid' className={classes.tableStyle} justifyContent='center' alignItems='stretch' direction='column'>
        <Grid item justify='left'>
            <Typography
              variant='h3'
              align='left'
              className={classes.greeting}
              color='primary'
            >
              <b>Active Accounts</b>
            </Typography>
          </Grid>
        <DataGrid
            rows={rows}
            columns={gridColumns}
            initialState={{
                sorting: {
                  sortModel: [{ field: 'accountId', sort: 'asc' }],
                },
              }}
        />
    </Grid>
  );
};

export default AccountGrid;
