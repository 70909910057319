import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { MainHeader } from 'components/base/Header';
import { MainFooter } from 'components/base/Footer';
import { makeJsonGetRequest } from 'utils/CRUDUtils';
import AccountGrid from './AccountGrid';
import PdfGrid from './PdfGrid';

const NfoSmartAdministration = () => {
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [batchFiles, setBatchFiles] = useState([]);

  useEffect(() => {
    async function getActiveAccounts() {
      const path = '/administration/activeAccounts';
      const params = {  };
      const activeAccounts = await makeJsonGetRequest(path, params);
      setActiveAccounts(activeAccounts);
    }

    getActiveAccounts();
  }, [setActiveAccounts]);

  useEffect(() => {
    async function getBatchFiles() {
      const path = '/administration/batchFiles';
      const params = {  };
      const batchFiles = await makeJsonGetRequest(path, params);
      setBatchFiles(batchFiles);
    }

    getBatchFiles();
  }, [setBatchFiles]);

  return (
    <Grid container spacing={3}>
      <Grid item container>
        <MainHeader />
      </Grid>
      <Grid container justifyContent='center' alignItems='center' direction='column'>
        <AccountGrid activeAccounts={activeAccounts} />
        <PdfGrid batches={batchFiles} />
      </Grid>
      <MainFooter />
    </Grid>
  );
};

export default NfoSmartAdministration;
