import { updateCardDesigns } from 'components/customizeCard/CustomCardSetters';
import {
  addFieldHtmlToEditorCanvas,
  clearContents,
  clearHiddenCanvas,
  getQuillsInSaveableFormat,
  hasActivatedQuills,
  removeBlankQuills,
  removeOutlineFromEdits,
  scaleEditsToCanvas,
  setNewToolbar,
  transferEditsToHiddenCanvas,
} from 'components/customizeCard/EditorDOMUtils';
import { initiateQuill } from 'components/customizeCard/Quill';

class QuillTracker {
  constructor(localCard) {
    this.quillList = [];
    this.toolbar = null;
    this.localCard = localCard;
  }

  setToolbar() {
    this.toolbar = document.getElementById('quill-tools-container').innerHTML;
  }

  addQuillFields(designData, activePane) {
    const fieldData = designData.fieldData;
    const fieldCount = designData.fieldCount;
    if (fieldData && fieldData !== 'QR') {
      this.quillList[activePane] = [];
      addFieldHtmlToEditorCanvas(designData.fieldData);

      for (var i = 0; i < fieldCount; i++) {
        const quill = initiateQuill(i);
        if (i < fieldCount - 1) {
          setNewToolbar(this.toolbar);
        }
        this.quillList[activePane].push(quill);
      }

      for (var j = 0; j < this.quillList[activePane].length; j++) {
        this.quillList[activePane][j].root.onblur = () => {
          this.saveDataToDB(activePane);
          if (hasActivatedQuills()) {
            this.localCard.promises.push(this.saveEditsToImage(activePane));
          }
        };
      }
    }
  }

  saveDataToDB = async (activePane) => {
    var div = document.createElement('div');
    const quills = this.quillList[activePane];
    for (var i = 0; i < quills.length; i++) {
      getQuillsInSaveableFormat(i, div, quills);
    }

    const saveData = div.innerHTML;
    const designData = { activePane: activePane, data: saveData };
    await updateCardDesigns(this.localCard, designData);
  };

  saveEditsToImage = async (activePane) => {
    const sequence = activePane + 1;
    const hiddenCanvas = document.getElementById('hidden-canvas-' + sequence);

    transferEditsToHiddenCanvas(hiddenCanvas);
    removeOutlineFromEdits();
    removeBlankQuills();
    scaleEditsToCanvas(hiddenCanvas);
    await this.localCard.capturePaneImage('hidden-canvas-' + sequence, sequence, 'pdf');
    clearHiddenCanvas(hiddenCanvas);
  };

  closeEditors(activePane) {
    if (this.quillList[activePane]) {
      const editorCanvas = document.getElementById('editor-canvas');

      setNewToolbar(this.toolbar);
      clearContents(editorCanvas);
      for (var i = 0; i < this.quillList[activePane].length; i++) {
        const quill = this.quillList[activePane][i];
        quill.enable(false);
      }
    }
  }
}

export default QuillTracker;
