import { connect } from 'react-redux';
import { selectCard } from 'redux/actions/CardActions';
import CardSelector from 'components/chooseCard/CardSelector';

const mapStateToProps = (state) => {
  return {
    occasionName: state.currentCard.occasion,
    occasionId: state.occasionProps[state.currentCard.occasion],
    existingCards: state.existingCards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSelectCard: (card) => dispatch(selectCard(card)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    occasionName: stateProps.occasionName,
    occasionId: stateProps.occasionId,
    existingCards: stateProps.existingCards,
    func: ownProps.func,
    selectCard: (card) => dispatchProps.dispatchSelectCard(card),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CardSelector);
