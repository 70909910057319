export const downloadDocument = (filename, byteData, format) => {
  var a = document.createElement('a');
  a.download = filename;
  a.href = makeBlobUrlFromBase64String(byteData, format);
  a.click();
};

export const makeBlobUrlFromBase64String = (data, outputType) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const url = window.URL.createObjectURL(
    new Blob([byteArray], { type: outputType })
  );
  return url;
};
