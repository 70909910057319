import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import VisibleOccasionTile from 'components/chooseCard/containers/VisibleOccasionTile';
import { CARD_TASK } from 'constants/Cards';
import { makeJsonGetRequest } from 'utils/CRUDUtils';
import keycloak from 'config/keycloak';

const useStyles = makeStyles(() => ({
  greeting: {
    width: 500,
  },
}));

const OccasionTileSelector = ({
  existingCards,
  addTask,
  addOccasion,
  addOccasionProps,
  setExistingCard,
}) => {
  const classes = useStyles();
  const [occasionTypes, setOccasionTypes] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const tasks = CARD_TASK.STEPS;
  const occasionsWithCard = useMemo(() => new Map(), []);

  useEffect(() => {
    const getOccasions = async () => {
      const types = [];
      const occasions = await makeJsonGetRequest('/occasion/list');
      occasions.forEach((occasion) => {
        const freq = occasion.occasionType.occasionType;
        if (types.indexOf(freq) === -1) {
          types.push(freq);
        }

        setOccasions((prevOccasions) => [
          ...prevOccasions,
          {
            freq: freq,
            occasion: occasion.occasionName,
          },
        ]);

        addOccasionProps(occasion);
      });

      await getCustomCardsForAccount();
      setOccasionTypes((prevOccasionTypes) => [...prevOccasionTypes, ...types]);
    };

    async function getCustomCardsForAccount() {
      const path = '/card/getCustomCardsForAccount';
      const params = { accountId: keycloak.subject };
      const customCards = await makeJsonGetRequest(path, params);

      for (var i = 0; i < customCards.length; i++) {
        const occasionName = customCards[i].card.occasion.occasionName;
        if (!occasionsWithCard.has(occasionName)) {
          occasionsWithCard.set(occasionName, customCards[i]);
        }
        setExistingCard(occasionName, customCards[i]);
      }
      return;
    }

    if (isFirstRender) {
      setIsFirstRender(false);
      getOccasions();
    }
  }, [isFirstRender, existingCards, occasionsWithCard, addOccasionProps, setExistingCard]);

  return (
    <Grid container item justify='center'>
      <Grid container justify='center' xs={10} lg={12}>
        <Grid container direction='column' lg={12} alignContent='center' spacing={3}>
          <Grid container item justify='center'>
            <Typography
              id='home-greeting-msg'
              variant='h3'
              align='center'
              className={classes.greeting}
              color='primary'
            >
              <b>Dashboard</b>
            </Typography>
          </Grid>
          <Grid container item>
            <Typography id='home-greeting-msg' variant='h5' className={classes.greeting}>
              Manage Your Cards
            </Typography>
          </Grid>

          <Grid
            container
            item
            justify='space-around'
            className='grey-border-container'
            style={{ padding: '30px' }}
          >
            {occasionTypes.map((type) => (
              <Grid item>
                <Typography
                  id={type + '-card-type-header'}
                  variant='h6'
                  align='center'
                  gutterBottom
                >
                  {type}
                </Typography>
                <Grid container justify='center' spacing={3}>
                  {occasions.map((occasion) => {
                    if (occasion.freq === type) {
                      addOccasion(occasion);
                      if (type === 'On-Demand') {
                        addTask(occasion, tasks[0]);
                      } else {
                        for (let i = 0; i < tasks.length; i++) {
                          addTask(occasion, tasks[i]);
                        }
                      }
                      return (
                        <VisibleOccasionTile
                          occasion={occasion}
                          occasionsWithCard={occasionsWithCard}
                        />
                      );
                    }
                    return <div />;
                  })}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OccasionTileSelector;
