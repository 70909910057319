import { connect } from 'react-redux';
import { selectCard, setCustomCardId } from 'redux/actions/CardActions';
import CardSelectorDialog from 'components/chooseCard/CardSelectorDialog';

const mapStateToProps = (state, ownProps) => {
  return {
    open: ownProps.open,
    setOpen: ownProps.setOpen,
    activeCard: ownProps.activeCard,
    handleNext: ownProps.handleNext,
    occasion: state.currentCard.occasion,
    existingCards: state.existingCards,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setCustomCardId: (id) => dispatch(setCustomCardId(id)),
  selectCard: (card) => dispatch(selectCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardSelectorDialog);
