export const existingCards = (state = {}, action) => {
  switch (action.type) {
    case 'SET_EXISTING_CARD':
      return { ...state, [action.occasion]: action.card };
    default:
      return state;
  }
};

export const cardsWithSchedules = (state = [], action) => {
  switch (action.type) {
    case 'SET_CARDS_WITH_SCHEDULES':
      const newState = [];
      for (const card of action.cards) {
        const occasionName = card.card.occasion.occasionName;
        if (!newState.includes(occasionName)) {
          newState.push(occasionName);
        }
      }
      newState.sort();
      return newState;
    default:
      return state;
  }
};

export default existingCards;
