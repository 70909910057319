import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const MediaUploadProgress = (props) => {
  const classes = useStyles();
  const progress = props.progress;

  return (
    <div
      className={classes.root}
      style={{ display: progress ? 'initial' : 'none' }}
    >
      <Box display='flex' alignItems='center'>
        <Box width='100%' mr={1}>
          <LinearProgress variant='determinate' value={progress} />
        </Box>
        <Box minWidth={35}>
          <Typography variant='body2' color='textSecondary'>
            {`${progress}%`}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default MediaUploadProgress;
