import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import CustomCard from 'components/customizeCard/CustomCard';
import { updateCustomCard } from 'components/customizeCard/CustomCardSetters';
import StockSelector from 'components/customizeCard/StockSelector';
import { getEnvelopeStocks, getPaperStocks } from 'components/customizeCard/CustomCardGetters';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import VisiblePreviewer from 'components/customizeCard/containers/VisiblePreviewer';
import CustomizerExitDialog from 'components/customizeCard/CustomizerExitDialog';

const Customizer = ({ cardId, orientation, func }) => {
  const localCard = useMemo(() => new CustomCard(cardId, orientation), [cardId, orientation]);
  const [paperStocks, setPaperStocks] = useState([]);
  const [envelopeStocks, setEnvelopeStocks] = useState([]);
  const [chosenPaper, setChosenPaper] = useState();
  const [chosenEnvelope, setChosenEnvelope] = useState();
  const [exitDialogOpen, setExitDialogOpen] = useState(false);
  const [isNextDesired, setIsNextDesired] = useState(false);
  const [pdf, setPDF] = useState(null);

  useEffect(() => {
    const getStocks = async () => {
      const stagedPaperStocks = await getPaperStocks(localCard);
      const stagedEnvelopeStocks = await getEnvelopeStocks(localCard);

      setPaperStocks(stagedPaperStocks);
      setEnvelopeStocks(stagedEnvelopeStocks);
      setChosenPaper(localCard.paperStockId);
      setChosenEnvelope(localCard.envelopeStockId);
    };

    getStocks();
  }, [localCard]);

  useEffect(() => {
    const updateStocks = () => {
      localCard.paperStockId = chosenPaper;
      localCard.envelopeStockId = chosenEnvelope;
      updateCustomCard(localCard);
    };

    if (localCard.customCardId && chosenEnvelope && chosenPaper) {
      updateStocks();
    }
  }, [localCard, chosenEnvelope, chosenPaper]);

  const openCustomizerExitDialog = async (isNext) => {
    await Promise.all(localCard.promises);
    const pdf = await localCard.makePreviewPDF();
    setPDF(pdf);
    localCard.makeProductPDF();
    setExitDialogOpen(true);
    setIsNextDesired(isNext);
  };

  const confirmCard = () => {
    if (isNextDesired) {
      func.handleNext();
    } else {
      func.handleBack();
    }
  };

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      spacing={5}
      xs={11}
      style={{ marginTop: '0px' }}
    >
      <Grid container item spacing={2} justify='center'>
        <Grid container xs={12} style={{ position: 'relative' }} justify='center'>
          <VisiblePreviewer localCard={localCard} />
        </Grid>
      </Grid>
      <Grid container item justify='center' style={{ display: 'none' }}>
        <StockSelector
          id='paper-selector'
          stockName='Paper'
          availableStocks={paperStocks}
          setChosen={setChosenPaper}
          initial={chosenPaper}
        />
        <StockSelector
          id='envelope-selector'
          stockName='Envelope'
          availableStocks={envelopeStocks}
          setChosen={setChosenEnvelope}
          initial={chosenEnvelope}
        />
      </Grid>
      <Grid container item justify='space-between' spacing={3}>
        <Grid item>
          <Button
            id='back-button'
            variant='contained'
            color='primary'
            onClick={() => openCustomizerExitDialog(false)}
          >
            <Grid container justify='space-between'>
              <ArrowBackRoundedIcon />
              <Typography id='change-card-button-text'>Change Card</Typography>
            </Grid>
          </Button>
        </Grid>
        <Grid item>
          <Button
            id='next-button'
            variant='contained'
            color='primary'
            onClick={() => openCustomizerExitDialog(true)}
          >
            <Grid container justify='space-between'>
              <Typography id='next-button-text'>Continue</Typography>
              <ArrowForwardRoundedIcon />
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <CustomizerExitDialog
        open={exitDialogOpen}
        pdf={pdf}
        setOpen={setExitDialogOpen}
        confirmCard={confirmCard}
      />
    </Grid>
  );
};

export default Customizer;
