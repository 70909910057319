import { makeFilePostRequest, makePostRequest } from 'utils/CRUDUtils';

export const getNewRecipients = async (dbRecipients, newRecipients, isReplaceTask) => {
  var processedRecipientData = {
    recipients: null,
    addedRecipients: 0,
  };

  if (dbRecipients && !isReplaceTask) {
    const path = '/recipient/removeDuplicateRecipients';
    const body = {
      originalRecipients: dbRecipients,
      newRecipients: newRecipients,
    };
    const response = await makePostRequest(path, body);
    const uniqueRecipients = await response.json();
    processedRecipientData.addedRecipients = calculateAddedRecipients(
      dbRecipients,
      newRecipients,
      uniqueRecipients
    );
    processedRecipientData.recipients = uniqueRecipients;
  } else {
    processedRecipientData.addedRecipients = newRecipients.length;
    processedRecipientData.recipients = newRecipients;
  }
  return processedRecipientData;
};

const calculateAddedRecipients = (dbRecipients, newRecipients, processedRecipients) => {
  const duplicates = dbRecipients.length + newRecipients.length - processedRecipients.length;
  return newRecipients.length - duplicates;
};

export const parseFile = async (file, customCardId) => {
  const path = '/recipient/parseRecipients';
  const params = { customCardId: customCardId };
  const response = await makeFilePostRequest(path, file, params);
  const result = await response.json();
  const data = {
    recipients: result.recipients,
    errorText: result.errorMessage,
    rowError: result.invalidRowFileData,
  };
  return data;
};
