import { connect } from 'react-redux';
import OccasionTile from 'components/chooseCard/OccasionTile';
import {
  selectCard,
  selectFlow,
  selectOccasion,
  selectFrequency,
  setCustomCardId,
} from 'redux/actions/CardActions';

const mapStateToProps = (state, ownProps) => {
  return {
    occasion: ownProps.occasion.occasion,
    occasionId: state.occasionProps[ownProps.occasion.occasion],
    occasionsWithCard: ownProps.occasionsWithCard,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectOccasion: () => dispatch(selectOccasion(ownProps.occasion.occasion)),
  selectFrequency: () => dispatch(selectFrequency(ownProps.occasion.freq)),
  selectFlow: (flow) => dispatch(selectFlow(flow)),
  setCustomCardId: (id) => dispatch(setCustomCardId(id)),
  selectCard: (card) => dispatch(selectCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OccasionTile);
