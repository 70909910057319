import React from 'react';
import { Grid } from '@material-ui/core';
import 'UnderConstruction.css';

const UnderConstructionLandingPage = () => {
    return (
        <Grid 
            container 
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={8}>
                <h1 class="center">Nfosmart Redesign Launching Soon!</h1>
                <p class="center">"We are an innovative technology company specializing in the
                development of advanced QR code solutions."</p>
            </Grid>
            <Grid 
                container 
                item xs={8}
                justifyContent="center"
                alignItems="center"

            >
                {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
                <img src="https://i.postimg.cc/ZnzYZHCW/blob-bdbc170.png" alt="QR Embed" height={228} width={408}></img>
            </Grid>
        </Grid>
    );
}

export default UnderConstructionLandingPage;