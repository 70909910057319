import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeJsonGetRequest } from 'utils/CRUDUtils';

  const useStyles = makeStyles(() => ({
    fullWidth: {
      width: '100%'
    },
    tableContainer: {
      maxHeight: 300
    },
    tableCell: {
      fontSize: '1.1rem',
      textAlign: 'center'
    },
  }));

  const CardCostDialog = ({ open, setOpen, addToSchedule }) => {
    const classes = useStyles();
    const columns = [
      { id: 'minCards', label: 'Min Cards Per Mailing' },
      { id: 'maxCards', label: 'Max Cards Per Mailing' },
      { id: 'cardCost', label: 'Cost Per Card' },
      { id: 'stampCost', label: 'Stamp Cost Per Card' }
    ];
    const [rows, setRows] = useState([]);

    useEffect(() => {
      const getCardCosts = async () => {
        let path = '/payment/getCardCosts';
        let cardCosts = await makeJsonGetRequest(path);
        cardCosts = cardCosts.sort((a, b) => a.maxBatchSize - b.maxBatchSize);
  
        let minCardsPerBatch = 1;
        let maxCardCost = 0;
        let maxStampCost = 0;
        let rowArray = [];
  
        for (let cardCost of cardCosts) {
          if (cardCost.maxBatchSize === -1) {
            maxCardCost = cardCost.cardCost;
            maxStampCost = cardCost.stampCost;
          } else {
            rowArray.push({
              minCards: minCardsPerBatch,
              maxCards: cardCost.maxBatchSize,
              cardCost: '$' + cardCost.cardCost.toFixed(2),
              stampCost: '$' + cardCost.stampCost.toFixed(2)
            });
            minCardsPerBatch = cardCost.maxBatchSize + 1;
          }
        }
  
        rowArray.push({
          minCards: minCardsPerBatch,
          maxCards: '∞',
          cardCost: '$' + maxCardCost.toFixed(2),
          stampCost: '$' + maxStampCost.toFixed(2)
        });
        setRows(rowArray);
      };

      getCardCosts();
    }, []);

    return (
      <Dialog open={open} maxWidth='md'>
        <DialogTitle id='cost-dialog-title' disableTypography>
          <Grid container justify='space-between' alignItems='center'>
            <Typography variant='h6'>Business Pricing</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <TableContainer className={classes.tableContainer}>
              <Table
                stickyHeader
                aria-label='sticky table'
                style={{
                  borderRight: '2px solid black',
                  borderLeft: '2px solid black',
                  borderBottom: '2px solid black',
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={column.id}
                        size='small'
                        className={classes.tableCell}
                        style={{
                          borderTop: '2px solid black',
                          borderBottom: '2px solid black',
                          borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                        }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        role='checkbox'
                        key={'row-' + index}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                        }}
                      >
                        {columns.map((column, i) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              size='small'
                              className={classes.tableCell}
                              style={{
                                borderRight: i === columns.length - 1 ? 'none' : '2px solid black',
                              }}
                            >
                              <span style={{ whiteSpace: "break-spaces"}}>{value}</span>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            item
            justify='space-between'
            style={{
              paddingInline: '0px',
            }}
          >
            <Typography>
              *All costs are included in the Cost Per Card
            </Typography>
            <Button
              id='next-button'
              variant='contained'
              color='primary'
              onClick={() => {
                setOpen(false);
                addToSchedule();
              }}
            >
              <Grid container justify='space-between'>
                <Typography id='next-button-text'>
                  Next
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    );
  };
  
  export default CardCostDialog;
  