import React, { useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, makeStyles, Typography, Paper, Tooltip } from '@material-ui/core';
import {
  getCustomCard,
  getNewCardDesigns,
  getSavedCardDesigns,
} from 'components/customizeCard/CustomCardGetters';
import HiddenCanvas from 'components/customizeCard/HiddenCanvas';
import PaneStepper from 'components/customizeCard/PaneStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { setLocalCustomCardData } from 'components/customizeCard/CustomCardSetters';
import Editor from 'components/customizeCard/Editor';
import QuillTracker from 'components/customizeCard/QuilllTracker';
import Skeleton from 'react-loading-skeleton';
import MediaSaver from 'components/customizeCard/MediaSaver';

const useStyles = makeStyles((theme) => ({
  preview: {
    width: '100%',
    height: 0,
    position: 'relative',
  },
  previewProgress: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  xsDisappear: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  xsAppear: {
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'initial',
    },
  },
}));

const Previewer = ({ localCard, occasionId, orientation, setCustomCardId }) => {
  const classes = useStyles();
  const quillTracker = useMemo(() => new QuillTracker(localCard), [localCard]); //tracks instances of editable fields
  const isLandscape = orientation === 'Landscape';
  const [hasAllPanesLoaded, setHasAllPanesLoaded] = useState(false);
  const [designs, setDesigns] = useState([]);
  const [activePane, setActivePane] = useState(0);
  const [isQRCodePane, setIsQRCodePane] = useState(false);
  const [hasQuills, setHasQuills] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const paneNames = isLandscape
    ? ['Front', 'Inside Top', 'Inside Bottom', 'Back']
    : ['Front', 'Inside Left', 'Inside Right', 'Back'];

  useEffect(() => {
    const getCards = async () => {
      const savedCustomCard = await getCustomCard(localCard, occasionId);
      if (savedCustomCard) {
        setLocalCustomCardData(localCard, savedCustomCard);
        await getSavedCardDesigns(localCard);
      } else {
        await getNewCardDesigns(localCard);
      }

      setCustomCardId(localCard.customCardId);
      setDesigns([...localCard.customCardDesigns]);
    };

    if (designs.length === 0) {
      getCards();
      quillTracker.setToolbar();
    }
  }, [designs, occasionId, localCard, setCustomCardId, quillTracker]);

  useEffect(() => {
    if (localCard.cardImages.length > 0) {
      localCard.setPanePreview(activePane);
    }
  }, [localCard, activePane]);

  useEffect(() => {
    const getQuillFields = (activePane) => {
      const fieldData = designs[activePane].data;
      const fieldCount = designs[activePane].fieldCount;
      const designData = { fieldData: fieldData, fieldCount: fieldCount };
      quillTracker.addQuillFields(designData, activePane);
      if (fieldData && fieldData !== 'QR') {
        setHasQuills(true);
      }
    };

    if (localCard.cardImages.length > 0) {
      if (isFirstRender) {
        for (var i = 0; i < designs.length; i++) {
          localCard.setPanePreview(i);
          const fieldData = designs[i].data;
          if (fieldData && fieldData !== 'QR') {
            getQuillFields(i);
            quillTracker.saveEditsToImage(i);
            quillTracker.closeEditors(i);
            setHasQuills(false);
          }
        }

        localCard.setPanePreview(0);
        setIsFirstRender(false);
      } else {
        getQuillFields(activePane);
      }
      setIsQRCodePane(designs[activePane].data === 'QR');
    }
  }, [designs, localCard, activePane, quillTracker, isFirstRender, hasAllPanesLoaded]);

  useEffect(() => {
    const getPanesLoaded = async () => {
      const count = await localCard.countPanesLoaded();
      if (count === designs.length && designs.length) {
        setHasAllPanesLoaded(true);
      }
    };

    getPanesLoaded();
  }, [localCard.hasPanesCaptured, localCard, designs.length]);

  const handleNextPane = () => {
    setHasQuills(false);
    quillTracker.closeEditors(activePane);
    setActivePane((prevActivePane) => prevActivePane + 1);
  };

  const handlePrevPane = () => {
    setHasQuills(false);
    quillTracker.closeEditors(activePane);
    setActivePane((prevActivePane) => prevActivePane - 1);
  };

  return (
    <Grid container item direction='column' alignItems='center' spacing={3}>
      <Grid id='pane-tracker' container item justify='space-around'>
        <IconButton
          id='pane-left-button'
          className={classes.xsAppear}
          size='medium'
          component='span'
          onClick={handlePrevPane}
          disabled={activePane === 0}
          style={{ display: 'inline-table' }}
        >
          <KeyboardArrowLeft fontSize='large' className={classes.xsAppear} />
        </IconButton>
        <Grid container justify='center' alignContent='center' xs={6} sm={12}>
          <Typography id='paneName-display' className={classes.xsDisappear} variant='h5'>
            Customize Your Card - {paneNames[activePane]}
          </Typography>
          <Typography id='paneName-display-mobile' className={classes.xsAppear} variant='h6'>
            {paneNames[activePane]}
          </Typography>
        </Grid>
        <IconButton
          id='pane-right-button'
          className={classes.xsAppear}
          size='medium'
          component='span'
          onClick={handleNextPane}
          disabled={activePane === 3 || !hasAllPanesLoaded}
          style={{ display: 'inline-table' }}
        >
          <KeyboardArrowRight fontSize='large' className={classes.xsAppear} />
        </IconButton>
      </Grid>
      <Grid container item justify='center' alignItems='center' style={{ width: '100%' }}>
        <IconButton
          id='pane-left-button'
          className={classes.xsDisappear}
          size='medium'
          component='span'
          onClick={handlePrevPane}
          disabled={activePane === 0}
          style={{ display: 'inline-table' }}
        >
          <KeyboardArrowLeft fontSize='large' className={classes.xsDisappear} />
        </IconButton>
        <Grid
          id='previewer'
          container
          item
          className='well'
          xl={isLandscape ? 7 : 6}
          lg={isLandscape ? 8 : 7}
          md={isLandscape ? 7 : 6}
          sm={isLandscape ? 9 : 8}
          xs={12}
        >
          <Tooltip
            id='quill-edit-tooltip'
            title={
              <Typography id='quill-edit-tooltip-text'>
                Click a blue field to edit. <br /> Highlight contents to style.
              </Typography>
            }
            open={hasQuills && hasAllPanesLoaded}
            placement='right-start'
          >
            <Paper
              id='pane-preview'
              className={classes.preview}
              style={{
                paddingBottom: isLandscape ? '71.43%' : '140%',
              }}
              square
            >
              <Editor hasQuills={hasQuills} />
              <MediaSaver
                isLandscape={isLandscape}
                localCard={localCard}
                isFirstRender={isFirstRender}
                isQRCodePane={isQRCodePane}
              />

              {!hasAllPanesLoaded && <Skeleton className={classes.previewProgress} />}
              <img src='' alt='' id='pane-image' className='preview-pic' />
            </Paper>
          </Tooltip>
          {designs.map((design) => {
            const pane = design.pane;
            const backgroundDesignUrl = design.cardDesignBackgroundUrl;
            const sequence = design.sequence;
            return (
              <HiddenCanvas
                backgroundDesignUrl={backgroundDesignUrl}
                sequence={sequence}
                editable={pane.editable}
                paneName={pane.pane}
                isLandscape={isLandscape}
                localCard={localCard}
              />
            );
          })}
        </Grid>
        <IconButton
          id='pane-right-button'
          className={classes.xsDisappear}
          size='medium'
          component='span'
          onClick={handleNextPane}
          disabled={activePane === 3 || !hasAllPanesLoaded}
          style={{ display: 'inline-table' }}
        >
          <KeyboardArrowRight fontSize='large' className={classes.xsDisappear} />
        </IconButton>
      </Grid>
      <Grid item>
        <PaneStepper activePane={activePane} />
      </Grid>
    </Grid>
  );
};

export default Previewer;
