import { connect } from 'react-redux';
import Scheduler from 'components/scheduleCard/Scheduler';

const mapStateToProps = (state, ownProps) => {
  return {
    occasion: state.currentCard.occasion,
    func: ownProps.func,
  };
};

export default connect(mapStateToProps)(Scheduler);
