import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { MainHeader } from 'components/base/Header';
import VisibleOccasionTileSelector from 'components/chooseCard/containers/VisibleOccasionTileSelector';
import { MainFooter } from 'components/base/Footer';
import keycloak from 'config/keycloak';
import { makeJsonGetRequest } from 'utils/CRUDUtils';
import MasterSchedule from 'components/scheduleCard/MasterSchedule';

const HomePage = ({ setCardsWithSchedules }) => {
  const [savedCardsWithSchedules, setSavedCardsWithSchedules] = useState([]);

  useEffect(() => {
    async function getCustomCardsWithSchedules() {
      const path = '/scheduler/getCustomCardsWithSchedules';
      const params = { accountId: keycloak.subject };
      const customCards = await makeJsonGetRequest(path, params);
      setSavedCardsWithSchedules(customCards);
      setCardsWithSchedules(customCards);
    }

    getCustomCardsWithSchedules();
  }, [setCardsWithSchedules]);

  useEffect(() => {
    async function updateAccount() {
      const path = '/account/updateAccount';
      const params = {};
      makeJsonGetRequest(path, params);
    }
    updateAccount();
  });

  return (
    <Grid container spacing={3}>
      <Grid item container>
        <MainHeader />
      </Grid>
      <Grid item container justify='center' alignContent='center'>
        <Grid container xs={12} lg={9} xl={6} spacing={7}>
          <VisibleOccasionTileSelector />
          <MasterSchedule savedCards={savedCardsWithSchedules} />
        </Grid>
      </Grid>
      <MainFooter />
    </Grid>
  );
};

export default HomePage;
