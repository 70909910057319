export const selectOccasion = (occasion) => ({
  type: 'SELECT_OCCASION',
  occasion,
});

export const selectFrequency = (frequency) => ({
  type: 'SELECT_FREQUENCY',
  frequency,
});

export const selectFlow = (flow) => ({
  type: 'SELECT_FLOW',
  flow,
});

export const selectCard = (card) => ({
  type: 'SELECT_CARD',
  card,
});

export const setCustomCardId = (id) => ({
  type: 'SET_CUSTOM_CARD_ID',
  customCardId: id,
});

export const setExistingCard = (occasion, card) => ({
  type: 'SET_EXISTING_CARD',
  occasion: occasion,
  card: card,
});

export const setCardsWithSchedules = (cards) => ({
  type: 'SET_CARDS_WITH_SCHEDULES',
  cards,
});

export default selectOccasion;
