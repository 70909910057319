import React, { useEffect, useState } from 'react';
import 'App.css';
import { Button, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Coverflow from 'react-coverflow';
import { makeJsonGetRequest } from 'utils/CRUDUtils';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import InfoIcon from '@material-ui/icons/Info';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import VisibleCardSelectorDialog from 'components/chooseCard/containers/VisibleCardSelectorDialog';

const useStyles = makeStyles(() => ({
  info: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    right: '-30px',
    transform: 'translate(-50%, -50%)',
  },
}));

const CardSelector = ({ occasionId, occasionName, existingCards, func, selectCard }) => {
  const classes = useStyles();
  const hasExistingCard = existingCards.hasOwnProperty(occasionName);
  const [cards, setCards] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const getCards = async () => {
      const stagedCards = [];
      const params = { occasionId: occasionId };
      const cards = await makeJsonGetRequest('/occasion/getCards', params);

      cards.forEach((card) => {
        stagedCards.push(card);
      });

      setCards(stagedCards);
      setVisibility(true);
      setActiveCard(stagedCards[0]);
    };

    getCards();
  }, [occasionId]);

  const handleNext = (card) => {
    selectCard(card);
    func.handleNext();
  };

  if (visibility) {
    return cards.length === 0 ? (
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        xs={11}
        spacing={5}
        style={{ height: '300px' }}
      >
        <Grid item>
          <Typography id='no-cards-msg' variant='h5'>
            No Cards Available Yet
          </Typography>
        </Grid>

        <Grid container item className='full-width' justify='center'>
          <Button
            id='cancel-button'
            component={Link}
            to='/home'
            variant='contained'
            color='primary'
          >
            <Typography variant='h6'>Back to Dashboard</Typography>
          </Button>
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        direction='column'
        justify='space-evenly'
        alignItems='center'
        xs={11}
        spacing={3}
      >
        <VisibleCardSelectorDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          activeCard={activeCard}
          handleNext={handleNext}
        />
        <Grid item style={{ position: 'relative' }}>
          <Typography id='select-card-msg' variant='h4' align='center'>
            Select a {occasionName} Card
          </Typography>
          <Tooltip
            id='selection-tooltip'
            title={
              <Typography id='selection-tooltip-text'>
                Click or scroll through cards to switch between them! <br />
                Double-click a card or use the button to select!
              </Typography>
            }
            placement='bottom'
            arrow
          >
            <InfoIcon color='disabled' className={classes.info} />
          </Tooltip>
        </Grid>
        <Grid item className='full-width'>
          <div id='coverflow'>
            <Coverflow
              height={400}
              displayQuantityOfSide={2}
              enableHeading={false}
              enableScroll={true}
              active={0}
            >
              {cards.map((card) => (
                <div onClick={() => setActiveCard(card)}>
                  <img
                    className='coverflow-image'
                    id={card.description + '-image'}
                    alt={card.description}
                    src={card.imageFileUrl}
                    onDoubleClick={() => (hasExistingCard ? setDialogOpen(true) : handleNext(card))}
                  />
                </div>
              ))}
            </Coverflow>
          </div>
        </Grid>
        <Grid container item className='full-width' justify='space-between'>
          <Button id='back-button' variant='contained' color='primary' component={Link} to='/home'>
            <Grid container justify='space-between'>
              <ArrowBackRoundedIcon />
              <Typography id='back-button-text'>Back</Typography>
            </Grid>
          </Button>
          <Button
            id='select-button'
            variant='contained'
            color='primary'
            onClick={() => (hasExistingCard ? setDialogOpen(true) : handleNext(activeCard))}
          >
            <Grid container justify='space-between'>
              <Typography id='select-button-text'>{'Select & Customize'}</Typography>
              <ArrowForwardRoundedIcon />
            </Grid>
          </Button>
        </Grid>
      </Grid>
    );
  }
  return <div />;
};

export default CardSelector;
