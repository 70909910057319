import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Dialog, Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeJsonGetRequest, makePostRequest } from 'utils/CRUDUtils';
import { getLongMonthNameAndYear } from 'utils/DateUtils';

const useStyles = makeStyles((theme) => ({
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 400,
  },
  tableCell: {
    fontSize: '1.1rem',
  },
  link: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const RecipientSchedule = ({
  occasion,
  customCardId,
  scheduleId,
  setLastUpdateDate,
  setRecipientSchedules,
  setHasRecipientsToSchedule,
  setCurrentMonthRecipientsResponse,
}) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = [
    { id: 'occasion', label: 'Occasion' },
    { id: 'newRecipientCount', label: 'New Recipients' },
    { id: 'totalRecipientCount', label: 'Total Recipients' },
  ];

  useEffect(() => {
    const currentDate = new Date();
    var lastUpdatedDate = null;
    getSchedules();
    getCurrentMonthRecipientsWithNoBatch();

    async function getSchedules() {
      await getRowData();
      const recipients = await fetchRecipients();
      const savedSchedules = await getSchedulesForRecipients(recipients);
      const recipientsWithoutSchedules = await getRecipientsWithoutSchedules(recipients);
      const newSchedules = await createSchedules(recipientsWithoutSchedules);
      const combinedSchedules = await getRecipientSchedules([...savedSchedules, ...newSchedules]);

      if (combinedSchedules.schedulesWithoutStatuses.length) {
        setHasRecipientsToSchedule(true);
        lastUpdatedDate = new Date(combinedSchedules.schedulesWithoutStatuses[0].createdOn);
        setLastUpdateDate(lastUpdatedDate.toLocaleString());
      } else {
        const recipientScheduleStatuses = await getRecipientScheduleStatuses();
        lastUpdatedDate = new Date(recipientScheduleStatuses[0].createdOn);
        setLastUpdateDate(lastUpdatedDate.toLocaleString());
      }

      setRecipientSchedules(combinedSchedules.schedulesWithoutStatuses);
      sortSchedulesIntoMonths(combinedSchedules);
      setOpen(false);
    }

    async function getCurrentMonthRecipientsWithNoBatch() {
      const path = '/scheduler/getCurrentMonthRecipientsWithNoBatch';
      const params = { customCardId: customCardId };
      const response = await makeJsonGetRequest(path, params);
      setCurrentMonthRecipientsResponse(response);
    }

    async function getRowData() {
      const monthOccasionKeys = [];
      for (var i = 0; i < 12; i++) {
        const monthOccasionKey = getLongMonthNameAndYear(
          new Date().setMonth(currentDate.getMonth() + i)
        );
        monthOccasionKeys.push({
          occasion: monthOccasionKey + ' - ' + occasion,
          newRecipientCount: 0,
          totalRecipientCount: 0,
        });
      }
      setRows(monthOccasionKeys);
      return;
    }

    async function fetchRecipients() {
      const path = '/recipient/getRecipientsForCard';
      const params = { customCardId: customCardId };
      const savedRecipients = await makeJsonGetRequest(path, params);
      setProgress(25);
      setLoadMessage('Retrieving Recipients...');
      return savedRecipients;
    }

    async function getSchedulesForRecipients(recipients) {
      const path = '/scheduler/getSchedulesForRecipients';
      const response = await makePostRequest(path, recipients);
      setProgress(50);
      setLoadMessage('Processing Recipients...');
      return await response.json();
    }

    async function getRecipientsWithoutSchedules(recipients) {
      const path = '/scheduler/getRecipientsWithoutSchedules';
      const response = await makePostRequest(path, recipients);
      return await response.json();
    }

    async function createSchedules(recipients) {
      const path = '/scheduler/createSchedulesFromRecipients';
      const params = { scheduleFrequencyId: scheduleId };
      const response = await makePostRequest(path, recipients, params);
      setProgress(75);
      setLoadMessage('Scheduling Recipients...');
      return await response.json();
    }

    async function getRecipientSchedules(schedules) {
      const path = '/scheduler/getRecipientSchedules';
      const response = await makePostRequest(path, schedules);
      setProgress(100);
      return await response.json();
    }

    async function getRecipientScheduleStatuses() {
      const path = '/scheduler/getRecipientScheduleStatusesForCard';
      const params = { customCardId: customCardId };
      return makeJsonGetRequest(path, params);
    }

    async function sortSchedulesIntoMonths(schedules) {
      setRows((prevRows) => {
        const newArr = [...prevRows];
        const schedulesWithoutStatuses = schedules.schedulesWithoutStatuses;
        const schedulesWithStatuses = schedules.schedulesWithStatuses;

        for (let i = 0; i < schedulesWithoutStatuses.length; i++) {
          const date = new Date(schedulesWithoutStatuses[i].recipientOccasionDate);
          const monthIndex = date.getUTCMonth() - currentDate.getMonth();
          if (monthIndex >= 0) {
            newArr[monthIndex].newRecipientCount++;
            newArr[monthIndex].totalRecipientCount++;
          } else {
            newArr[monthIndex + 12].newRecipientCount++;
            newArr[monthIndex + 12].totalRecipientCount++;
          }
        }

        for (let j = 0; j < schedulesWithStatuses.length; j++) {
          const date = new Date(schedulesWithStatuses[j].recipientOccasionDate);
          const monthIndex = date.getUTCMonth() - currentDate.getMonth();
          if (monthIndex >= 0) {
            newArr[monthIndex].totalRecipientCount++;
          } else {
            newArr[monthIndex + 12].totalRecipientCount++;
          }
        }
        return newArr;
      });
    }
  }, [
    customCardId,
    scheduleId,
    occasion,
    setLastUpdateDate,
    setRecipientSchedules,
    setCurrentMonthRecipientsResponse,
    setHasRecipientsToSchedule,
  ]);

  return (
    <Grid container item style={{ flex: 1 }}>
      <Dialog open={open} disableBackdropClick maxWidth='xs' fullWidth>
        <Grid container justify='center' style={{ height: '175px' }}>
          <Grid container direction='column' justify='space-evenly' style={{ width: '90%' }}>
            <Typography id='loading-msg' variant='h5' align='center'>
              {loadMessage}
            </Typography>
            <Box display='flex' alignItems='center'>
              <Box width='100%' mr={1}>
                <LinearProgress variant='determinate' value={progress} />
              </Box>
              <Box minWidth={35}>
                <Typography id='load-progress' variant='body2' color='textSecondary'>
                  {`${progress}%`}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <Paper id='schedules-grid' className={classes.rootPaper}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{
                      border: '2px solid black',
                      borderRight: 'none',
                    }}
                    size='small'
                    className={classes.tableCell}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    role='checkbox'
                    key={'row-' + index}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#e6e6e6' : 'inherit',
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          size='small'
                          style={{ borderLeft: '2px solid black' }}
                          className={classes.tableCell}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default RecipientSchedule;
