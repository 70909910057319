import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { setCustomCardMediaInfo } from 'components/customizeCard/CustomCardSetters';
import { getMedia } from 'components/customizeCard/CustomCardGetters';
import {
  isExceedingFileSizeLimit,
  isExceedingVideoDurationLimit,
} from 'components/customizeCard/MediaUtils';
import axios from 'axios';
import MediaUploadProgress from 'components/customizeCard/MediaUploadProgress';

const useStyles = makeStyles(() => ({
  landscape: {
    width: '7in',
    height: '5in',
    boxShadow: 'none',
  },
  portrait: {
    width: '5in',
    height: '7in',
    boxShadow: 'none',
  },
  removeButton: {
    borderRadius: '25px',
    marginBottom: '5%',
  },
  mediaContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    overflow: 'hidden',
    position: 'absolute',
  },
}));

const MediaSaver = (props) => {
  const classes = useStyles();
  const isFirstRender = props.isFirstRender;
  const isLandscape = props.isLandscape;
  const localCard = props.localCard;
  const isQRCodePane = props.isQRCodePane;
  const [hasStagedMedia, setHasStagedMedia] = useState(false);
  const [hasMedia, setHasMedia] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getMediaInfo = async () => {
      if (localCard.customCardId) {
        const media = await getMedia(localCard.customCardId);
        if (media) {
          localCard.customCardMediaId = media.customCardMediaId;
          localCard.mediaUrl = media.mediaUrl;
          if (localCard.mediaUrl) {
            setHasMedia(true);
          }

          await localCard.addQRCodeToPreview();
          await localCard.resolvePromises();
        }
      }
    };

    if (isFirstRender) {
      getMediaInfo();
    }
  }, [
    isFirstRender,
    localCard,
    localCard.mediaUrl,
    localCard.customCardId,
    localCard.customCardMediaId,
  ]);

  const attachMedia = async () => {
    var input = document.getElementById('uploader');
    let formData = new FormData();
    const file = input.files[0];

    if (file) {
      if (isExceedingFileSizeLimit(file, input)) {
        return;
      }

      if (isExceedingVideoDurationLimit(file, input)) {
        return;
      }

      formData.append('file', file);
      formData.append('customCardId', localCard.customCardId);

      const response = await axios.post('/media/saveMedia', formData, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      });

      if (response.status !== 200) {
        alert(await response.data);
        input.value = null;
        return;
      }

      const mediaUrl = await response.data;
      localCard.mediaUrl = mediaUrl;
      await setCustomCardMediaInfo(localCard, file.name);

      setHasMedia(true);
      await localCard.addQRCodeToPreview();
      await localCard.resolvePromises();
      localCard.setPanePreview(1);
    } else {
      alert('No media chosen!');
    }

    localCard.setPanePreview(1);
    handleClose();
  };

  const removeMedia = async () => {
    fetch('/media/removeMedia', {
      method: 'POST',
      body: localCard.mediaUrl,
    });

    localCard.mediaUrl = '';
    await setCustomCardMediaInfo(localCard, '');
    setProgress(0);

    document.getElementById('pane-image').src = '';
    setHasMedia(false);
    await localCard.capturePaneImage('QR-canvas', 2, 'preview');
    localCard.setPanePreview(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      item
      direction='column'
      justify='space-evenly'
      className={classes.mediaContainer}
      style={{
        height: isQRCodePane ? (isLandscape ? '90%' : '50%') : 0,
        border: isQRCodePane ? 'dashed #24b1dd' : 'none',
      }}
    >
      <div style={{ overflow: 'hidden', height: 0, width: 0 }}>
        <Paper
          id='QR-canvas'
          className={isLandscape ? classes.landscape : classes.portrait}
          square
        >
          {hasMedia && (
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'
              style={{ width: '100%', height: '100%' }}
            >
              <Typography
                id='card-QR-top-msg'
                variant='h5'
                align='center'
                gutterBottom
              >
                Personalized Content
              </Typography>
              <Grid item style={{ maxWidth: '25%'}}>
                <img src='' alt='' id='QR-code-image' className='preview-pic' />
              </Grid>
              <Typography
                id='card-QR-bottom-msg'
                variant='h6'
                align='center'
                gutterBottom
              >
                Scan to Access
              </Typography>
            </Grid>
          )}
        </Paper>
      </div>
      {hasMedia ? (
        <Grid
          container
          direction='column'
          justify='flex-end'
          style={{ height: '100%' }}
        >
          <Grid container justify='center'>
            <Button
              id='remove-media-button'
              variant='outlined'
              onClick={removeMedia}
              color='primary'
              classes={{ outlinedPrimary: classes.removeButton }}
            >
              Remove media
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction='column'
          justify='center'
          spacing={3}
          style={{ height: '100%' }}
        >
          <Grid container item direction='column' justify='center'>
            <Typography id='default-media-msg' align='center' variant='h6'>
              Add a photo or video as a QR Code
            </Typography>
          </Grid>
          <Grid container item justify='center'>
            <Button
              id='save-media-button'
              variant='contained'
              onClick={() => setOpen(true)}
              color='primary'
              style={{ borderRadius: '25px' }}
            >
              <Grid container direction='column'>
                <Typography>Add media</Typography>
              </Grid>
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              disableBackdropClick
              maxWidth='md'
            >
              <DialogTitle id='dialog-title'>
                Select a photo or video for your card
              </DialogTitle>
              <DialogContent dividers>
                <Grid
                  container
                  direction='column'
                  justify='space-around'
                  alignContent='center'
                  style={{ height: '250px' }}
                >
                  <Typography id='media-desc'>
                    Media will appear as a QR Code on the Inside Left pane.
                    <br />
                    <em style={{ color: 'grey' }}>
                      Max file size: {process.env.REACT_APP_MAX_FILE_MB}MB
                    </em>
                    <br />
                    <em style={{ color: 'grey' }}>
                      Max video duration:{' '}
                      {process.env.REACT_APP_MAX_VIDEO_DURATION_SECONDS} seconds
                    </em>
                  </Typography>
                  <input
                    type='file'
                    accept='video/*, image/*'
                    id='uploader'
                    style={{ maxWidth: '350px' }}
                    onChange={() => setHasStagedMedia(true)}
                  />
                  <Typography
                    align='center'
                    style={{
                      marginTop: '30px',
                      display: progress ? 'initial' : 'none',
                    }}
                  >
                    Saving media to card...
                  </Typography>
                  <MediaUploadProgress progress={progress} />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  id='cancel-back-button'
                  variant='contained'
                  onClick={handleClose}
                  disabled={progress}
                >
                  Cancel
                </Button>
                <Button
                  id='continue-back-button'
                  variant='contained'
                  onClick={attachMedia}
                  color='primary'
                  disabled={!hasStagedMedia || progress}
                >
                  Attach
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MediaSaver;
