import React from 'react';
import 'App.css';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import NavigationBar from 'components/base/Header';

const useStyles = makeStyles((theme) => ({
  aboutText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.25rem',
    },
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction='column'
      justify='space-between'
      style={{ height: '40%' }}
    >
      <NavigationBar />
      <Grid
      container
      direction='column'
      style={{ width: '65%', alignSelf: 'center' }}
      >
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-header'
            className={classes.aboutText}
            variant='h3'
            gutterBottom
            >
            About Us
            </Typography>
        </Grid>
        <Grid container justify='left' alignContent='top'>
            <Typography
            id='terms-subtextOne'
            className={classes.aboutText}
            variant='h6'
            gutterBottom
            >
              NfoSmart is where users can connect and interact with content and people wherever they are.<br/><br/>We provide a platform 
              where users can connect via the IoT for the best convenience and experiences in everyday places and activities they enjoy.
            </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default About;
