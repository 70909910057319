import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Alert from '@material-ui/lab/Alert';
import { makeJsonGetRequest, makePostRequest } from 'utils/CRUDUtils';
import UnsavedFileDialog from 'components/sendCard/UnsavedFileDialog';
import AddRecipientsIcon from 'components/sendCard/AddRecipientsIcon';
import NoRecipientsDisplay from 'components/sendCard/NoRecipientsDisplay';
import RecipientGrid from 'components/sendCard/RecipientGrid';
import { getNewRecipients, parseFile } from 'components/sendCard/RecipientProcessor';
import CircularProgress from '@material-ui/core/CircularProgress';
import { downloadDocument } from 'utils/FileUtils';
import TutorialDialog from 'components/sendCard/TutorialDialog';

const useStyles = makeStyles(() => ({
  alert: {
    position: 'absolute',
    top: '-55px',
    width: '400px',
    zIndex: 2,
  },
  loadingIcon: {
    display: 'none',
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: '30%',
  },
}));

const RecipientSelector = (props) => {
  const classes = useStyles();
  const [csvStatusMessage, setCsvStatusMessage] = useState(null);
  const [csvApproved, setCsvApproved] = useState(false);
  const [recipients, setRecipients] = useState(null);
  const [replace, setReplace] = useState(false);
  const [hasUnsavedFile, setHasUnsavedFile] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [tutorialOpen, setTutorialOpen] = useState(false);

  useEffect(() => {
    async function fetchRecipients() {
      const path = '/recipient/getRecipientsForCard';
      const params = { customCardId: props.customCardId };
      const savedRecipients = await makeJsonGetRequest(path, params);
      if (savedRecipients.length > 0) {
        setRecipients(savedRecipients);
      }
      setIsFirstRender(false);
    }

    fetchRecipients();
  }, [props.customCardId]);

  const handleNext = async () => {
    var path = '/recipient/updateAndInsertRecipients';
    var params = null;
    if (replace) {
      path = '/recipient/dropAndInsertRecipients';
      params = { customCardId: props.customCardId };
    }
    document.getElementById('loading-icon').style.display = 'initial';
    await makePostRequest(path, recipients, params);
    props.func.handleNext();
  };

  const handleDrop = (files) => {
    getRecipientsFromFile(files[0]);
  };

  const handleSubmit = (isReplaceTask) => {
    var input = document.getElementById('icon-upload');
    setHasUnsavedFile(true);
    getRecipientsFromFile(input.files[0], isReplaceTask);

    if (isReplaceTask) {
      setReplace(true);
    } else {
      setReplace(false);
    }
  };

  const handleDelete = async () => {
    const path = '/recipient/dropRecipients';
    const params = { customCardId: props.customCardId };
    await makeJsonGetRequest(path, params);
    setRecipients(null);
  };

  const getRecipientsFromFile = async (file, isReplaceTask) => {
    const parseData = await parseFile(file, props.customCardId);
    if (parseData.errorText) {
      setCsvStatusMessage(
        parseData.errorText
      );
      setCsvApproved(false);
    } else {
      const processedRecipients = await getNewRecipients(
        recipients,
        parseData.recipients,
        isReplaceTask
      );
      setRecipients(processedRecipients.recipients);
      if (parseData.rowError) {
        setCsvStatusMessage(
          processedRecipients.addedRecipients +
            ' entries were added, but some entries are missing data. Correct errors in the ErrorRows file.'
        );
        setCsvApproved(false);
        downloadDocument('ErrorRows.csv', parseData.rowError, 'text/csv');
      } else {
        setCsvStatusMessage(
          'Upload was successful! Added ' + processedRecipients.addedRecipients + ' recipients.'
        );
        setCsvApproved(true);
      }
    }
  };

  const showCsvStatus = (message) => {
    if (message) {
      return (
        <Alert
          id='recipient-notification'
          variant='filled'
          severity={csvApproved ? 'success' : 'error'}
          onClose={() => {
            setCsvStatusMessage(null);
            setCsvApproved(false);
          }}
          className={classes.alert}
        >
          {message}
        </Alert>
      );
    }
  };

  return (
    <Grid container direction='column' justify='center' alignItems='center' xs={10}>
      <Grid item style={{ marginBottom: '25px' }}>
        <Typography id='add-recipients-msg' variant='h4' align='center'>
          {props.occasionName} Recipients
        </Typography>
      </Grid>
      <Grid
        container
        item
        className='full-width'
        justify='space-between'
        direction='column'
        alignItems='center'
        wrap='nowrap'
        style={{
          border: '2px solid gray',
          position: 'relative',
          padding: '10px',
        }}
      >
        <AddRecipientsIcon handleSubmit={handleSubmit} handleDelete={handleDelete} hasRecipients={recipients} />
        <CircularProgress id='loading-icon' className={classes.loadingIcon} size={50} />

        {showCsvStatus(csvStatusMessage)}
        {!isFirstRender &&
          (recipients ? (
            <RecipientGrid occasion={props.occasionName} recipients={recipients} />
          ) : (
            <NoRecipientsDisplay handleDrop={handleDrop} />
          ))}
      </Grid>
      <Grid container item justify='space-between' style={{ marginTop: '20px' }}>
        <UnsavedFileDialog handleBack={props.func.handleBack} hasUnsavedFile={hasUnsavedFile} />
        <Button
          id='tutorial-button'
          variant='outlined'
          color='primary'
          onClick={() => setTutorialOpen(true)}
          style={{ display: recipients ? 'initial' : 'none' }}
        >
          Tutorial
        </Button>
        <TutorialDialog open={tutorialOpen} setOpen={setTutorialOpen} />
        <Button
          id='next-button'
          variant='contained'
          color='primary'
          onClick={() => handleNext()}
          disabled={!recipients}
        >
          <Grid container justify='space-between'>
            <Typography id='next-button-text'>Schedule</Typography>
            <ArrowForwardRoundedIcon />
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};

export default RecipientSelector;
