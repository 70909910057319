import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

const UnsavedFileDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleBack = () => {
    if (props.hasUnsavedFile) {
      setOpen(true);
    } else {
      props.handleBack();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        id='back-button'
        variant='contained'
        color='primary'
        onClick={handleBack}
      >
        <Grid container justify='space-between'>
          <ArrowBackRoundedIcon />
          <Typography id='back-button-text'>Back</Typography>
        </Grid>
      </Button>
      <Dialog open={open} onClose={handleClose} disableBackdropClick>
        <DialogTitle id='alert-dialog-title'>
          You have unsaved changes!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Your changes will not be saved. Do you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id='cancel-back-button'
            variant='contained'
            onClick={handleClose}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            id='continue-back-button'
            variant='contained'
            onClick={() => {
              handleClose();
              props.handleBack();
            }}
            color='primary'
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnsavedFileDialog;
