import { makeJsonGetRequest } from 'utils/CRUDUtils';
import { getLongMonthNameAndYear } from 'utils/DateUtils';

export const mapRecipientStatusesToOccasions = (dateRanges, cards, map) => {
  const promises = [];

  for (var i = 0; i < dateRanges.length; i++) {
    const startDate = dateRanges[i].firstDay;
    const endDate = dateRanges[i].lastDay;
    const timeWindow = { startDate: startDate, endDate: endDate };
    for (const card of cards) {
      const monthKey =
        getLongMonthNameAndYear(new Date(startDate)) + ' - ' + card.card.occasion.occasionName;

      if (!map.has(monthKey)) {
        map.set(monthKey, {
          total: 0,
          scheduled: 0,
          paid: 0,
          printed: 0,
          mailed: 0
        });
      }
      const countsAndStatuses = map.get(monthKey);

      promises.push(getRecipientCountForDatesAndCard(card, timeWindow, countsAndStatuses));
      promises.push(getRecipientScheduleStatuses(card, timeWindow, countsAndStatuses));
    }
  }
  return Promise.all(promises);
};

async function getRecipientCountForDatesAndCard(card, timeWindow, data) {
  const path = '/scheduler/getScheduledRecipientCountForDateRangeAndCard';
  const params = {
    customCardId: card.customCardId,
    startDate: timeWindow.startDate,
    endDate: timeWindow.endDate,
  };
  const recipientCount = await makeJsonGetRequest(path, params);
  data.total = recipientCount;
}

async function getRecipientScheduleStatuses(card, timeWindow, data) {
  const path = '/scheduler/getRecipientScheduleStatusesForCardAndTimePeriod';
  const params = {
    customCardId: card.customCardId,
    startDate: timeWindow.startDate,
    endDate: timeWindow.endDate,
  };
  const recipientStatuses = await makeJsonGetRequest(path, params);

  for (var i = 0; i < recipientStatuses.length; i++) {
    const status = recipientStatuses[i];
    data[status.recipientStatus.recipientStatus.toLowerCase()]++;
  }
}
