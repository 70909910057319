export const addOccasionProps = (occasion) => ({
  type: 'ADD_OCCASION_PROPS',
  occasion,
});

export const addOccasion = (occasion) => ({
  type: 'ADD_OCCASION',
  occasion,
});

export const addTask = (occasion, task) => ({
  type: 'ADD_TASK',
  task,
  occasion,
});

export const completeTask = (occasion, id) => ({
  type: 'COMPLETE_TASK',
  id,
  occasion,
});

export const undoTask = (occasion, id) => ({
  type: 'UNDO_TASK',
  id,
  occasion,
});
