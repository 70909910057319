import { connect } from 'react-redux';
import {
  addOccasion,
  addTask,
  addOccasionProps,
} from 'redux/actions/OccasionActions';
import OccasionTileSelector from 'components/chooseCard/OccasionTileSelector';
import { setExistingCard } from 'redux/actions/CardActions';

const mapStateToProps = (state) => {
  return {
    frequency: state.currentCard.frequency,
    existingCards: state.existingCards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAddOccasion: (occasion) => dispatch(addOccasion(occasion)),
  dispatchAddTask: (occasion, task) => dispatch(addTask(occasion, task)),
  dispatchAddOccasionProps: (occasion) => dispatch(addOccasionProps(occasion)),
  dispatchSetExistingCard: (occasion, card) =>
    dispatch(setExistingCard(occasion, card)),
});

const mergeProps = (stateProps, dispatchProps) => {
  return {
    existingCards: stateProps.existingCards,
    addOccasion: (occasion) => {
      if (!stateProps.frequency) dispatchProps.dispatchAddOccasion(occasion);
    },
    addTask: (occasion, task) => {
      if (!stateProps.frequency) dispatchProps.dispatchAddTask(occasion, task);
    },
    addOccasionProps: (occasion) =>
      dispatchProps.dispatchAddOccasionProps(occasion),
    setExistingCard: (occasion, card) =>
      dispatchProps.dispatchSetExistingCard(occasion, card),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OccasionTileSelector);
