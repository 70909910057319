import { makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  landscape: {
    width: '7in',
    height: '5in',
    boxShadow: 'none',
    position: 'relative',
  },
  portrait: {
    width: '5in',
    height: '7in',
    boxShadow: 'none',
    position: 'relative',
  },
}));

const HiddenCanvas = (props) => {
  const classes = useStyles();
  const sequence = props.sequence;
  const localCard = props.localCard;
  const canvas = 'hidden-canvas-' + sequence;

  useEffect(() => {
    localCard.promises.push(
      localCard.capturePaneImage(canvas, sequence, 'preview')
    );
  }, [canvas, sequence, localCard]);

  return (
    <div style={{ overflow: 'hidden', height: 0, width: 0 }}>
      <Paper
        id={canvas}
        className={props.isLandscape ? classes.landscape : classes.portrait}
        square
      >
        {props.backgroundDesignUrl && (
          <img
            id={'preview-pic-' + sequence}
            src={props.backgroundDesignUrl + '&' + new Date().toISOString()}
            alt=''
            className='preview-pic'
            crossOrigin='anonymous'
          />
        )}
      </Paper>
    </div>
  );
};

export default HiddenCanvas;
