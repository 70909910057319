import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    TextField
  } from '@material-ui/core';
  import React, { useState } from 'react';
  import CloseIcon from '@material-ui/icons/Close';
  import { makeJsonGetRequest } from 'utils/CRUDUtils';

  const DeleteAccountDialog = ({ open, setOpen, logOut }) => {
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleTextChange = (event) => {
      if (event.target.value.toLowerCase() === 'delete') {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    };
  
    const deleteAccount = () => {
        makeJsonGetRequest('/account/deleteAccount', {});
        logOut();
    };

    return (
      <Dialog open={open} disableBackdropClick maxWidth='sm'>
        <DialogTitle id='alert-dialog-title' disableTypography>
          <Grid container justify='space-between' alignItems='center'>
            <Typography variant='h6'>Delete Account</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justify='center'>
            <Grid container justify='center' spacing={3} xs={10}>
              <Grid item>
                <Typography id='alert-dialog-description' align='center'>
                  To confirm that you would like to permanently delete your account, please type 'delete' in the box below.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify='space-evenly' style={{ marginTop: '20px' }}>
            <TextField
              id='delete-text'
              variant='outlined'
              color='secondary'
              onChange={ handleTextChange }
            ></TextField>
            <Button
              id='delete-button'
              variant='contained'
              color='primary'
              onClick={() => deleteAccount()}
              disabled={ buttonDisabled }
            >
              Confirm
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default DeleteAccountDialog;
  