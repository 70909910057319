import keycloak from 'config/keycloak';
import jsPDF from 'jspdf';
import { updateCustomCard } from 'components/customizeCard/CustomCardSetters';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';

class CustomCard {
  constructor(cardId, orientation) {
    this.accountId = keycloak.subject;
    this.customCardId = null;
    this.cardId = cardId;
    this.orientationType = orientation;
    this.paperStockId = null;
    this.envelopeStockId = null;
    this.customCardDesigns = [];
    this.cardImages = [];
    this.promises = [];
    this.hasPanesCaptured = false;
    this.pdfImages = [];
    this.mediaUrl = '';
    this.customCardMediaId = 0;
    this.pdfUrl = '';
    this.frontPaneImage = '';
    this.insideLeftPaneImage = '';
    this.insideRightPaneImage = '';
    this.backPaneImage = '';
  }

  capturePaneImage = async (canvas, sequence, destination) => {
    const htmlCanvas = await html2canvas(document.getElementById(canvas), {
      useCORS: true,
      scale: 1
    });
    const image = new Image();
    image.src = htmlCanvas.toDataURL('image/png');
    htmlCanvas.toBlob(function(blob) {
      image.blob = blob;  
    }, 'image/png');

    if (destination === 'preview') {
      this.cardImages[sequence] = image;
    }
    this.pdfImages[sequence] = image;

    if (sequence === 1) {
      document.getElementById('pane-image').src = image.src;
    }
    return;
  };

  countPanesLoaded = async () => {
    await this.resolvePromises();
    this.hasPanesCaptured = true;
    var count = 0;
    for (var i = 0; i < this.cardImages.length; i++) {
      if (this.cardImages[i]) count++;
    }
    return count;
  };

  setPanePreview = (activePane) => {
    const index = activePane + 1;
    document.getElementById('pane-image').src = this.cardImages[index].src;
  };

  makeProductImages = async () => {
    const formData = new FormData();
    formData.append('frontImage', this.cardImages[1].blob);
    formData.append('insideLeftImage', this.cardImages[2].blob);
    formData.append('insideRightImage', this.cardImages[3].blob);
    formData.append('backImage', this.cardImages[4].blob);

    var url = new URL('/media/saveImages', process.env.REACT_APP_LANDING_URL);
    var params = { customCardId: this.customCardId };
    url.search = new URLSearchParams(params).toString();

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const imageUrls = await response.json();

      this.frontPaneImage = imageUrls[0];
      this.insideLeftPaneImage = imageUrls[1];
      this.insideRightPaneImage = imageUrls[2];
      this.backPaneImage = imageUrls[3];
      
      updateCustomCard(this);
    } catch {
      console.log('Card could not be uploaded.');
    }
  };

  makeProductPDF = async () => {
    var doc = makePrintingPDF(this, this.orientationType);
    var pdf = doc.output('blob');

    let formData = new FormData();
    formData.append('file', pdf);

    var url = new URL('/media/savePdf', process.env.REACT_APP_LANDING_URL);
    var params = { customCardId: this.customCardId };
    url.search = new URLSearchParams(params).toString();

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const pdfUrl = await response.text();
      this.pdfUrl = pdfUrl;
      updateCustomCard(this);
    } catch {
      console.log('Card could not be uploaded.');
    }
  };

  makePreviewPDF = async () => {
    let doc = makePDF(this, this.orientationType);
    return doc.output('bloburl');
  };

  addQRCodeToPreview = async () => {
    if (this.mediaUrl) {
      const image = await QRCode.toDataURL(this.mediaUrl);
      document.getElementById('QR-code-image').src = image;
      return this.promises.push(this.capturePaneImage('QR-canvas', 2, 'preview'));
    }
  };

  resolvePromises = () => {
    return Promise.all(this.promises);
  };
}

const makePDF = (card, orientation) => {
  var doc = new jsPDF({
    orientation: orientation.toLowerCase(),
    unit: 'in',
    format: [5, 7],
  });

  card.pdfImages.forEach((image) => {
    doc.addPage();
    doc.addImage(image, 'PNG', 0, 0);
  });
  doc.deletePage(1);
  return doc;
};

const makePrintingPDF = (card, orientation) => {
  let width = 0;
  let height = 0;
  if (orientation.toLowerCase() === 'portrait') {
    width = 5;
    height = 14;
  } else if (orientation.toLowerCase() === 'landscape') {
    width = 7;
    height = 10;
  }
  let doc = new jsPDF({
    orientation: 'portrait',
    unit: 'in',
    format: [width, height],
  });
  let offset = .5;
  doc.addImage(card.pdfImages[4], 'PNG', width, -1/2 * height - offset, width, height / 2, 'Back', 'NONE', 180);
  doc.addImage(card.pdfImages[1], 'PNG', 0, height / 2 - offset);
  doc.addPage();
  doc.addImage(card.pdfImages[2], 'PNG', 0, 0 - offset, width, height / 2);
  doc.addImage(card.pdfImages[3], 'PNG', 0, height / 2 - offset);
  return doc;
};

export default CustomCard;
