import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CustomizerExitDialog = ({ open, setOpen, pdf, confirmCard }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog open={open} disableBackdropClick maxWidth='sm' fullWidth>
      <DialogTitle id='dialog-title' disableTypography>
        <Grid container justify='space-between' alignItems='center'>
          <Typography variant='h6' gutterBottom>
            <b>Preview and Confirm Your Card</b>
          </Typography>
          <Typography>
            The card previewed below will be the card queued for printing. Please confirm your card
            changes or continue making edits.
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ maxHeight: '520px', backgroundColor: '#e5e5e5' }}>
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly', padding: '20px 0px' }}>
        <Button
          id='deny-button'
          variant='outlined'
          color='primary'
          onClick={() => {
            setOpen(false);
          }}
        >
          Keep Editing
        </Button>
        <Button id='confirm-button' variant='contained' color='primary' onClick={confirmCard}>
          Confirm Card
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomizerExitDialog;
