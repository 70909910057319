import React from 'react';
import { Grid } from '@material-ui/core';

const Editor = (props) => {
  const hasQuills = props.hasQuills;

  return (
    <div
      id='editor-container'
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: hasQuills ? 'initial' : 'none',
      }}
    >
      <div id='quill-tools-container'>
        <div id='toolbar-container'>
          <span class='ql-formats'>
            <select class='ql-font'>
              <option value='arial' selected>Arial</option>
            </select>
            <select class='ql-size'>
              <option value='18px' selected>
                18
              </option>
            </select>
          </span>
          <span class='ql-formats'>
            <button class='ql-bold'></button>
            <button class='ql-italic'></button>
          </span>
          <span class='ql-formats'>
            <select class='ql-align'></select>
          </span>
        </div>
      </div>
      <Grid
        id='editor-canvas'
        container
        className='editor-canvas'
        justify='center'
        alignContent='center'
        direction='column'
      />
    </div>
  );
};

export default Editor;
