import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeGetRequest } from 'utils/CRUDUtils';

const TutorialDialog = ({ open, setOpen }) => {
  useEffect(() => {
    async function fetchVideo() {
      const demoVideoUrl = await makeGetRequest('/media/getExampleVideoUrl');
      document.getElementById('csv-tutorial-video').src = demoVideoUrl;
    }

    if (open) {
      fetchVideo();
    }
  }, [open]);
  return (
    <Dialog open={open} maxWidth='lg' fullWidth onClose={() => setOpen(false)}>
      <DialogTitle id='dialog-title'>Tutorial</DialogTitle>
      <DialogContent dividers style={{ display: 'flex', justifyContent: 'center' }}>
        <video id='csv-tutorial-video' width='1000px' height='auto' controls src='' />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '20px 0px' }}>
        <Button
          id='close-button'
          variant='contained'
          color='primary'
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TutorialDialog;
