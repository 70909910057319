import { connect } from 'react-redux';
import RecipientSchedule from 'components/scheduleCard/RecipientSchedule';

const mapStateToProps = (state, ownProps) => {
  return {
    occasion: state.currentCard.occasion,
    customCardId: state.currentCard.customCardId,
    scheduleId: state.currentCard.scheduleId,
    setLastUpdateDate: ownProps.setLastUpdateDate,
    setRecipientSchedules: ownProps.setRecipientSchedules,
    setHasRecipientsToSchedule: ownProps.setHasRecipientsToSchedule,
    setCurrentMonthRecipientsResponse: ownProps.setCurrentMonthRecipientsResponse,
  };
};

export default connect(mapStateToProps)(RecipientSchedule);
