import React from 'react';
import { MainHeader } from 'components/base/Header';
import { Grid } from '@material-ui/core';
import VisibleCardStepper from 'components/base/containers/VisibleCardStepper';
import { MainFooter } from 'components/base/Footer';
import { useStore } from 'react-redux';

const CardWizard = (props) => {
  return (
    <Grid
      container
      direction='column'
      style={{ position: 'relative', flex: 1 }}
    >
      <Grid item container id='appbar-container'>
        <MainHeader />
      </Grid>
      <Grid
        item
        container
        justify='center'
        alignContent='center'
        style={{ flex: 1 }}
      >
        <Grid
          container
          xs={props.paperWidth[0]}
          lg={props.paperWidth[1]}
          xl={props.paperWidth[2]}
          id='component-container'
        >
          <Grid container className='grey-border-container'>
            <VisibleCardStepper
              activeStep={props.activeStep}
              index={props.index}
              checkpoint={props.checkpoint}
              lastSubTask={props.lastSubTask}
            />
          </Grid>
        </Grid>
      </Grid>
      <MainFooter />
    </Grid>
  );
};

export const CardChooser = () => {
  return <CardWizard activeStep={0} index={0} paperWidth={[8, 7, 5]} />;
};

export const CardCustomizer = () => {
  const store = useStore();
  const orientation = store.getState().currentCard.orientation;
  return orientation === 'Landscape' ? (
    <CardWizard activeStep={0} index={1} lastSubTask paperWidth={[12, 9, 7]} />
  ) : (
    <CardWizard activeStep={0} index={1} lastSubTask paperWidth={[12, 8, 6]} />
  );
};

export const CardSender = () => {
  return (
    <CardWizard
      activeStep={1}
      index={2}
      checkpoint
      lastSubTask
      paperWidth={[8, 6, 5]}
    />
  );
};

export const CardScheduler = () => {
  return (
    <CardWizard
      activeStep={2}
      index={3}
      checkpoint
      lastSubTask
      paperWidth={[12, 9, 6]}
    />
  );
};

export default CardWizard;
